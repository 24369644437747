import {Grid, Typography, Box, Tabs} from '@mui/material';
import React, {useRef} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useGetDidQuery} from '../services/requests';
import {
    StyledCircularProgress,
    StyledPaper,
    StyledGrid,
    StyledBroadsoftGrid,
    StyledNextButton,
    StyledTab
} from '../styles/mui_styles';
import DIDDetailsField from './DIDDetailsField';
import DIDHistory from './DIDHistory';
import WarningAlert from './WarningAlert';

function DIDDetails() {
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    const navigate = useNavigate();
    const [tabValue, setTabValue] = React.useState(0);
    const timestampRef = useRef(Date.now()).current;

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const {data, isLoading, isFetching} = useGetDidQuery({id, sessionId: timestampRef});


    const handleEditClick = () => {
        navigate("edit");
    }

    return (
        <Box className="details_block_divider">
            {
                isLoading || isFetching ?
                    <Box className="progress_block">
                        <StyledCircularProgress/>
                    </Box>
                    :
                    <>
                        {data?.blocked && <WarningAlert/> }
                        <Tabs className="tabs_block" value={tabValue} onChange={handleChange} aria-label="tabs"
                              TabIndicatorProps={{sx: {backgroundColor: "#80a1fd", height: "2px"}}}>
                            <StyledTab label="DETAILS"/>
                            <StyledTab label="HISTORY"/>
                        </Tabs>
                        {tabValue === 0 &&
                        <StyledPaper>
                            <Box className="details_block">
                                <Box className="history_title_block">
                                    <StyledNextButton onClick={handleEditClick}>Edit</StyledNextButton>
                                    <Typography className="general_title">General Information</Typography>
                                </Box>
                                <StyledGrid container>
                                    <Grid item>
                                        {["number", "status", "carrier"].map(field => (
                                            <DIDDetailsField field={field} value={data[field]}/>
                                        ))}
                                    </Grid>
                                    <Grid item>
                                        {["type", "partner", "customer"].map(field => (
                                            <DIDDetailsField field={field} value={data[field]}/>
                                        ))}
                                    </Grid>
                                    <Grid item>
                                        {["country", "state", "city"].map(field => (
                                            <DIDDetailsField field={field} value={data[field]}/>
                                        ))}
                                    </Grid>
                                    <Grid item>
                                        {["rateCenter", "portStatus", "date"].map(field => (
                                            <DIDDetailsField field={field}
                                                             value={field === "date" ? data.auditLogs : data[field]}/>
                                        ))}
                                    </Grid>
                                </StyledGrid>
                                <Box className="services_brodsoft_block">
                                    <Box className="details_services_block">
                                        <Typography className="general_title">Services</Typography>
                                        <StyledBroadsoftGrid container>
                                            <Grid item>
                                                {["fax", "mms"].map(field => (
                                                    <DIDDetailsField field={field} value={data[field]}/>
                                                ))}
                                            </Grid>
                                            <Grid item>
                                                {["sms", "smsMmsProvider"].map(field => (
                                                    <DIDDetailsField field={field} value={data[field]}/>
                                                ))}
                                            </Grid>
                                        </StyledBroadsoftGrid>
                                    </Box>
                                    <Box className="details_broadsoft_block">
                                        <Typography className="general_title">Broadsoft Information</Typography>
                                        <StyledBroadsoftGrid container>
                                            <Grid item>
                                                {["BSEnterpriseName", "BSEnterpriseId"].map(field => (
                                                    <DIDDetailsField field={field} value={data.broadSoftInfo[field]}/>
                                                ))}
                                            </Grid>
                                            <Grid item>
                                                {["BSGroupName", "BSGroupId"].map(field => (
                                                    <DIDDetailsField field={field} value={data.broadSoftInfo[field]}/>
                                                ))}
                                            </Grid>
                                        </StyledBroadsoftGrid>
                                    </Box>
                                </Box>
                            </Box>
                        </StyledPaper>
                        }
                        {tabValue === 1 &&
                        <DIDHistory data={data.auditLogs}/>
                        }
                    </>
            }
        </Box>
    )
}

export default DIDDetails;
