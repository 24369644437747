import {useState} from 'react';
import {Stack, IconButton, Typography, Fade, SvgIcon, Box} from '@mui/material';
import {StyledErrorAlert} from "../styles/mui_styles";
import CloseIcon from '@mui/icons-material/Close';
import {bulkImportErrorMessages} from "../constants/constants";

export default function ErrorAlert({type, location, setError}) {
    const [open, setOpen] = useState(true);

    const getColumn = (item, component) => {
        const splitItem = item.split(",");
        const columns = splitItem.map((col, i) => {
            if (col === "smsMmsProvider" || col === "sms" || col === "mms") {
                return `"${col === "sms" ? "SMS" : col === "mms" ? "MMS" : "SMS/MMS Provider"}"${splitItem.length - 1 > i ? ", " : ""}`;
            }
            const secondWordIndex = col.search(/[A-Z]/g);
            if (secondWordIndex > 0) {
                col = col.slice(0, secondWordIndex) + " " + col.slice(secondWordIndex)
            }
            return `"${col.charAt(0).toUpperCase()}${col.slice(1)}"${splitItem.length - 1 > i ? ", " : ""}`;
        })
        return <Typography className="file_span_bold" component={component}
                           fontWeight={"bold"}>{columns}</Typography>;
    }

    const getValue = (item, component) => {
        return <Typography className="file_span_bold" component={component}
                           fontWeight={"bold"}>{`"${item}"`}</Typography>;
    }

    const parseLocationData = (location) => {
        if (type !== "missing_required_data") {
            return location;
        } else {
            const locationToReturn = [];
            location.forEach((elem, i) => {
                const element = JSON.parse(JSON.stringify(elem))
                if (i === 0) {
                    locationToReturn.push(element);
                }

                if (locationToReturn[locationToReturn.length - 1].row !== element.row) {
                    locationToReturn.push(element);
                }

                if (!locationToReturn[locationToReturn.length - 1].field.includes(element.field)) {
                    locationToReturn[locationToReturn.length - 1].field = locationToReturn[locationToReturn.length - 1].field + "," + element.field;
                }
            });
            return locationToReturn;
        }
    }

    const setErrors = () => {
        if (type === "invalid_file_size") {
            setError(false);
        }
    }

    const getMismatchedFieldsText = (row) => {
        const fields = Object.keys(row).filter(key => key.includes("field"));
        const fieldsText = fields.map((field, i) => {
            return <>{getColumn(row[field], "span")}{i < fields.length - 1 ? ", " : ""}</>;
        })
        return <>{fieldsText}{" "}{`${fields.length > 1 ? "do" : "does"} not match the provided NPA/NXX combination.`}</>;
    }

    return (
        open &&
        <Stack sx={{marginBottom: 1}}>
            <Fade in={open} timeout={800}>
                <StyledErrorAlert
                    severity="error"
                    icon={false}
                    className={`${type.includes("warning") && "warning_alert"}`}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrors();
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    <SvgIcon className={`${type.includes("warning") ? "warning_icon" : "error_icon"}`}/>
                    <Box>
                        <Typography className="file_text">{bulkImportErrorMessages[type].message}</Typography>
                        {
                            location && parseLocationData(location).map(item => {
                                return type === "missing_required_columns" ?
                                    getColumn(item, "div") :
                                    type === "missing_required_data" ?
                                        <Typography className="file_span">{`Row ${item.row} - `}{getColumn(item.field, "span")}</Typography> :
                                    type === "invalid_data" && item.value ?
                                        <Typography className="file_span">{`Row ${item.row} - `}{getColumn(item.field, "span")}{` column cannot have `}{getValue(item.value, "span")}{` value.`}</Typography> :
                                    (type === "invalid_data_warning" && item.value) || type === "existing_did" || type === "non_existent_did" || type === "blocked_did" ?
                                        <Typography className="file_span">{`Row ${item.row} - `}{getValue(item.value, "span")}</Typography> :
                                    type === "did_duplication" ?
                                        <Typography className="file_span">{`Rows ${typeof item.row === "string" ? JSON.parse(item.row).join(", ") : (item.row).join(", ")} - `}{getValue(item.value, "span")}</Typography> :
                                    type === "data_mismatch" ?
                                        <Typography className="file_span">{`Row ${item.row} - `}{getColumn(item.field1, "span")}{` and `}{getColumn(item.field2, "span")}{item.field3 ? <>, {getColumn(item.field3, "span")}</> : ""}{` column values do not match.`}</Typography> :
                                    type === "mismatch_data_warning" ?
                                        <Typography className="file_span">{`Row ${item.row} - `}{getMismatchedFieldsText(item)}</Typography> :
                                    <Typography></Typography>
                            })
                        }
                    </Box>
                </StyledErrorAlert>
            </Fade>
        </Stack>
    );
}
