import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { mockData } from '../mock';
import { getAccessToken } from './getAccessToken';
const { REACT_APP_BASE_URL } = process.env;

const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    let token;
    try {
      token = await getAccessToken("silent");
    } catch (error) {
      //window.history.pushState({}, '', '/login');
      token = await getAccessToken("popup");
    }
    headers.set('Authorization', `Bearer ${token}`)
    return headers;
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    const newToken = await getAccessToken("popup");
    if (newToken) {
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result;
}


export const requests = createApi({
  reducerPath: 'requestsReducer',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 3600,
  tagTypes: ['AllDIDs', 'DIDdetails'],
  endpoints: (builder) => ({
    getDids: builder.query({
      query: (data) => {
        let params = {...data};
        const isBulk = Array.isArray(data.number)
        if (!data.number) {
          delete params.number;
        } 
        if(!data.sortField) {
          delete params.sortField;
          delete params.sortType;
        }
        if(!data.status && !params.number) {
         params.status = "Available,Assigned,Aging,Reserved";
        }
        if (isBulk) {
          return {
            method: 'POST',
            url: 'bulkSearch',
            body: params
          }
        }
        return {
          url: 'numbers',
          params: params,
        }
      },
      providesTags: ['AllDIDs'],
      transformResponse: (resp) => {
        console.log(resp)
        return resp.body;
        // return mockData;
      }
    }),
    getDid: builder.query({
      query: ({id, includeAuditLogs = true}) => {
        return {
          url: `number/${id}?includeAuditLogs=${includeAuditLogs}`,
        }
      },
      providesTags: ['DIDdetails'],
      transformResponse: (resp) => {
        resp.body.numberData.auditLogs = resp.auditLogs;
        resp.body.numberData.partner = resp.body.numberData.partner[0] || {};
        resp.body.numberData.customer = resp.body.numberData.customer[0] || {};
        resp.body.numberData.broadSoftInfo = resp.body.numberData.broadSoftInfo[0] || {};
        return resp.body.numberData;
      }
    }),
    getDidExternalData: builder.query({
      query: (params) => {
        return {
          url: `numberExternalData`,
          params: params,
          timeout: 3000,
        }
      },
      transformResponse: (resp) => {
        return resp.body;
      }
    }),
    getBSData: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `GetBSInfoByNumber`,
          params: params
        }
      },
      transformResponse: (resp) => {
        return resp.body;
      }
    }),
    getPartners: builder.query({
      query: (params) => {
        return {
          url: 'partners',
          params: params
        }
      },
      providesTags: ['Partners'],
      transformResponse: (resp) => {
        return resp.body;
      },
    }),
    getCustomers: builder.query({
      query: (params) => {
        const partnerId  = params?.partnerId;
        const url = partnerId ? `customers?partnerId=${partnerId}` : "customers";
        const {status, value, skip, limit} = params;
        return {
          url: url,
          params: params && {status, value, skip, limit}
        }
      },
      providesTags: ['Customers'],
      transformResponse: (resp) => {
        return resp.body;
      }
    }),
    syncPartnersCustomers: builder.mutation({
      query: (id) => {
        return {
          method: "GET",
          url: 'synchronize'
        }
      },
      invalidatesTags: ['Partners', 'Customers'],
      transformResponse: (resp) => {
        return resp.body;
      }
    }),
    addDid: builder.mutation({
      query: (body) => {
        return {
          method: "POST",
          url: 'number',
          body: body,
        }
      },
      invalidatesTags: ['AllDIDs', 'DIDdetails']
    }),
    bulkAddDid: builder.mutation({
      query: (body) => {
        return {
          method: "POST",
          url: `numbers?validateNpaNxx=${body.validateNpaNxx}`,
          body: body.file,
          "content-type": "multipart/form-data"
        }
      },
      invalidatesTags: ['AllDIDs']
    }),
    updateDid: builder.mutation({
      query: (value) => {
        return {
          method: "PATCH",
          url: `number/${value.id}`,
          body: value.dataToSend,
        }
      },
      invalidatesTags: ['AllDIDs', 'DIDdetails']
    }),
    bulkUpdateDid: builder.mutation({
      query: (body) => {
        return {
          method: "PATCH",
          url: `numbers?validateNpaNxx=${body.validateNpaNxx}`,
          body: body.file,
          "content-type": "multipart/form-data"
        }
      },
      invalidatesTags: ['AllDIDs', 'DIDdetails']
    }),
    returnDid: builder.mutation({
      query: (id) => {
        return {
          method: "DELETE",
          url: `number/${id}`,
        }
      },
      invalidatesTags: ['AllDIDs', 'DIDdetails']
    }),
    exportDids: builder.mutation({
      query: (data) => {
        let params = {...data};
        if (!data.number) {
          delete params.number;
        } 
        if(!data.sortField) {
          delete params.sortField;
          delete params.sortType;
        }
        if(!data.status) {
         params.status = "Available,Assigned,Aging,Reserved";
        }
        return {
          method: 'POST',
          url: 'export',
          body: params,
        }
      },
      transformResponse: (resp) => {
        return resp.body.link;
      },
    }),
    syncProviderData: builder.mutation({
      query: (data) => {
        const {provider, validate, file} = data;
        return {
          method: "POST",
          url: `ProvidersCompareDB`,
          params: {provider, validate},
          body: file,
          "content-type": "multipart/form-data"
        }
      },
      transformResponse: (resp) => {
        return resp.body
      }
    }),
  }),
});

export const {
    useGetDidQuery,
    useGetDidExternalDataQuery,
    useGetDidsQuery,
    useGetBSDataMutation, 
    useGetPartnersQuery,
    useGetCustomersQuery,
    useSyncPartnersCustomersMutation,
    useAddDidMutation,
    useUpdateDidMutation,
    useReturnDidMutation,
    useBulkAddDidMutation,
    useBulkUpdateDidMutation,
    useExportDidsMutation,
    useSyncProviderDataMutation
} = requests;
