import React from 'react';
import {Typography, SvgIcon, Box} from '@mui/material'; 
import {StyledEditButton } from '../../styles/mui_styles'

function SyncDataStepThree({carrier, loading, link, error, success}) {

  return (
    <>
        {loading ? 
            <Box className="step_three_container">
                <SvgIcon className="loading_icon_exception"/>
                <Typography className="step_three_title">
                    We are comparing DID Management System data with {carrier.label}.
                    Please do not close the window until it's complete.
                </Typography>
            </Box> :
        error  ?
                <Box className="step_three_container">
                    <Typography className="step_three_error">
                        Something went wrong.
                    </Typography>
                    <Typography className="step_three_error">
                        Please return to the main page and start the process from the beginning.
                    </Typography>
                </Box>:
        success  ?  
            <>
                {link ?
                    <Box className="step_three_container">
                        <SvgIcon className="icon_check"/>
                        <Typography className="step_three_title">
                            Data comparison with {carrier.label} is successfully completed. Please
                                click the button below to download indentified exceptions.
                        </Typography>
                        <StyledEditButton component="a" href={link}>
                            Download Exceptions Report
                        </StyledEditButton>
                    </Box>
                    :
                    <Box className="step_three_container">
                        <SvgIcon className="icon_check"/>
                        <Typography className="step_three_title">
                            Data comparison with {carrier.label} is successfully completed.
                            <Typography className="step_three_title">
                                No exceptions have been indentified.
                            </Typography>
                        </Typography>
                    </Box>
                }
            </> :
            <></>
            }
        </>
    )
}

export default SyncDataStepThree
