import React, {useState} from 'react';
import {Popover} from '@mui/material';
import {useMsal} from "@azure/msal-react";
import {StyledLogoutList, StyledMenuItem,StyledProfileIconButton} from "../../styles/mui_styles";
import { BrowserUtils } from "@azure/msal-browser";


export default function Logout() {

    const {instance} = useMsal();
    const signedInUser = instance.getActiveAccount()?.name;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleLogOut = async () => {
        await instance.logoutRedirect({
            account: instance.getActiveAccount(), 
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
        });
        await instance.handleRedirectPromise();
    }

    const getUserInitials = (user) => {
        const splitname = user.split(' ')
        let initials = "";
        for (const value of splitname) {
            initials = initials + value.charAt(0);
        }
        return initials;
    }

    return (
            <div className="profile_image">
            <StyledProfileIconButton size='small' aria-describedby={id} onClick={handleClick} className="account">
            {signedInUser && getUserInitials(signedInUser)}
            </StyledProfileIconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <StyledLogoutList>
                    <StyledMenuItem onClick={handleLogOut}>
                       Sign out
                    </StyledMenuItem>
                </StyledLogoutList>
            </Popover>
        </div>
    );
}
