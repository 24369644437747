import {useState, useEffect} from "react";
import {useGetCustomersQuery} from '../../services/requests';

function useGetCustomers({partnerId, sessionId}) {
    const [customersToSkip, setCustomersToSkip] = useState(0);
    const [customerValue, setCustomerValue] = useState("");
    const [skipCustomersRequest, setSkipCustomersRequest] = useState(false)

    const {data: customersData, isFetching, isLoading} = useGetCustomersQuery({
        limit: 20,
        skip: customersToSkip,
        value: customerValue,
        partnerId: partnerId, 
        sessionId: sessionId
    }, {skip: skipCustomersRequest || !partnerId});

    const [customersCache, setCustomersCache] = useState([]);
    
    useEffect(() => {
        let notConcated = true;
        if (customersData && notConcated) {
            setCustomersCache(prev => {
                const newData = JSON.parse(JSON.stringify(prev));
                return newData.concat(customersData)
            })
        }
        if (customersData && !customersData.length) {
            setSkipCustomersRequest(true);
        }
        return () => {
            notConcated = false;
        }
    }, [customersData])
    
    return {
        customersToSkip,
        setCustomersToSkip,
        customerValue,
        setCustomerValue,
        customersCache,
        setCustomersCache,
        isFetchingCustomers: isFetching, 
        isLoadingCustomers: isLoading,
        skipCustomersRequest,
        setSkipCustomersRequest
    };
}

export default useGetCustomers;