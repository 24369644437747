import React from 'react'
import {Typography, Box} from '@mui/material';
import {AddNewDIDFields} from '../constants/constants';
import {getFormattedDate} from "../utils/utils";

function DIDDetailsField({field, value}) {

    const getValue = () => {
        if (field === "number") {
            return `+${value}`;
        }
        if (field === "partner" || field === "customer") {
            return value.name ? 
                `${value.name}${value.status === "Canceled" || value.status === "Disabled" ? " (disabled)" : ""}` : 
                "No Data";
        }
        if (field === "date") {
            return value && value.length ? 
                getFormattedDate(value.find(item => item[0].action === "Create")[0]?.createdAt) : 
                "No Data";
        }
        if (value) {
            return value;
        } 
        return "No Data";
    }

    const getValueClassName = () => {
        if (field === "partner" || field === "customer") {
            return !value.name ? "no_data" : value.status === "Canceled" || value.status === "Disabled" ? "disabledPartnerCustomer" : "";
        }
        if (field === "date") {
            return !value.length ? "no_data" : "";
        }
        if (!value) {
            return "no_data";
        } 
        return "";
    }

    return (
        <Box className="did_details_divider" key={field}>
            <Typography className="did_details_title">
                {field === "date" ? "Date Created" : AddNewDIDFields[field].label}
            </Typography>
            <Typography
                className={`did_details_paragraph ${getValueClassName()}`}
            >
                {getValue()}
            </Typography>
        </Box>
    )
}

export default DIDDetailsField;