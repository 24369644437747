import * as React from 'react';
import {Box} from "@mui/material";
import {
    StyledConfirmButton,
    StyledEditButton,
    StyledConfirmDialog,
    StyledConfirmDialogContent,
    StyledConfirmDialogContentText,
    StyledConfirmDialogActions,
    StyledExportDialogTitle,
} from '../styles/mui_styles';
import {Typography} from "@mui/material";
import {didExternalDataErrorsInitialState} from "../constants/constants";


export default function ConfirmManualEditDialog ({data, setData, setDidExternalDataConfirmationDialog, action, handleSubmitDID}) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    const errors = JSON.parse(JSON.stringify(didExternalDataErrorsInitialState));
    setData(errors);
    setOpen(false);
    setDidExternalDataConfirmationDialog(false);
  };

  const handleConfirm = () => {
    handleClose();
    handleSubmitDID(action, true);
  }

    const getInvalidFields = (data) => {
        const invalidData = data.filter(row => row.property !== "npa_nxx" && row.invalid);
        const inValidFields = invalidData.map(row => `"${row.label}"`);
        return `${inValidFields.join(", ")} ${invalidData.length > 1 ? "do" : "does"} not match the provided NPA/NXX combination.`
    }

    return (
        <>
            <StyledConfirmDialog
                open={open}
                onClose={handleClose}
            >
                <StyledExportDialogTitle id="alert-dialog-title">
                    Confirm DID Details
                </StyledExportDialogTitle>
                <StyledConfirmDialogContent>
                    <StyledConfirmDialogContentText id="alert-dialog-description">
                        We couldn't verify the following DID information. Please double-check
                        that the information is correct.
                    </StyledConfirmDialogContentText>
                    <Box className="dialog_block">
                        <Typography component="div" className="dialog_paragraph">
                            {data.find(row => row.property === "npa_nxx").invalid ?
                                "Provided NPA/NXX combination cannot be found." :
                                getInvalidFields(data)
                            }
                        </Typography>
                        {data.map((row) => {
                            return (row.invalid || row.property === "npa_nxx") && (
                                <Typography className="dialog_paragraph" component="div">
                                  {`${row.label}: ${row.value}`}
                                </Typography>
                            )
                        })}
                    </Box>
                </StyledConfirmDialogContent>
                <StyledConfirmDialogActions>
                    <StyledEditButton onClick={handleClose} variant="outlined">Edit</StyledEditButton>
                    <StyledConfirmButton onClick={handleConfirm} variant="outlined">
                      {`Confirm & ${action === "edit" ?  "Update" : "Create"}`}
                    </StyledConfirmButton>
                </StyledConfirmDialogActions>
            </StyledConfirmDialog>
        </>
    );
}
