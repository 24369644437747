import React from 'react';
import {FormControlLabel,Checkbox} from "@mui/material";
import {StyledFormControlLabel} from "../styles/mui_styles";
import {AddNewDIDFields} from "../constants/constants";
import { withStyles } from '@material-ui/core/styles';

const checkBoxStyles = theme => ({
    root: {
        '&$checked': {
            color: '#80a1fd !important',
        },
    },
    checked: {},
})
const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
function DIDCheckbox({name, value, disabled, handleChange}) {

    return (
        <StyledFormControlLabel
            className="checkbox_block"
            control={<CustomCheckbox/>}
            label={AddNewDIDFields[name].label}
            checked={value}
            disabled={disabled}
            onChange={(e) => handleChange(name, e.target.checked)}
        />
    )
}

export default DIDCheckbox;