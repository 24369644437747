import React from 'react'
import { Box } from "@mui/material"
import Logout from './auth/Logout'
import { useNavigate } from "react-router-dom";

function GlobalHeader({clearDIDFilters}) {
  const navigate = useNavigate();
 
  const handleClick = ()=> {
    clearDIDFilters();
    navigate("/dids");
  }

  return (
    <Box className="global_header">
       <Box onClick={handleClick}>
          <Box className="bluip-logo_header"/>
       </Box>
        <Logout />
    </Box>
  )
}

export default GlobalHeader
