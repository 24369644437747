import {Routes, Route, Navigate, redirect} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material';
import Layout from "./components/Layout";
import Login from "./components/auth/Login";
import DIDsPage from "./components/DIDsPage";
import {useMsal, useIsAuthenticated} from "@azure/msal-react";
import {InteractionStatus} from "@azure/msal-browser";
import "./styles/style.scss";
import AddNewDID from "./components/AddNewDID";
import BulkImportUpdateDIDs from "./components/BulkImportUpdateDIDs";
import DIDsTable from "./components/DIDsTable";
import DIDDetails from "./components/DIDDetails";
import {StyledCircularProgress} from "./styles/mui_styles";
import React from "react";
import ExceptionsPage from "./components/exceptions_page/ExceptionsPage";
import SyncData from "./components/exceptions_page/SyncData";

const theme = createTheme({
    typography: {
        fontFamily: [
            'SegoeUI',
            'SegoeUI-Semibold',
            'Roboto',
        ].join(','),
    },
});

function LoginInProgress () {
    return (
        <div className="redirect_block">
            <StyledCircularProgress/>
        </div>
    )
}

// https://www.robinwieruch.de/react-router-private-routes/
function ProtectedRoute() {
    const {inProgress, accounts} = useMsal();

    if (accounts.length > 0 && inProgress === InteractionStatus.None) {
        return <Layout />
    } else if (accounts.length === 0 && inProgress === InteractionStatus.None) {
        return <Login />
    } else if (inProgress === InteractionStatus.Login || inProgress === InteractionStatus.AcquireToken) {
        return <LoginInProgress />
    } else {
        return null;
    }
};

function App() {

    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute></ProtectedRoute>
                    }

                >
                    <Route path="dids" element={<DIDsPage/>}>
                        <Route index element={<DIDsTable/>}/>
                        <Route path="new_did" element={<AddNewDID/>}/>
                        <Route path="bulk_import" element={<BulkImportUpdateDIDs/>}/>
                        <Route path="bulk_update" element={<BulkImportUpdateDIDs/>}/>
                        <Route path=":id" element={<DIDDetails/>}/>
                        <Route path=":id/edit" element={<AddNewDID/>}/>
                    </Route>
                    <Route path="exceptions" element={<ExceptionsPage/>}>
                        <Route index element={<SyncData/>}/>
                    </Route>
                </Route>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
