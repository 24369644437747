import {useEffect, useContext, useState} from 'react';
import {useGetDidsQuery} from '../services/requests';
import InputAdornment from "@material-ui/core/InputAdornment";
import {Box, Paper, TableRow, TableCell, SvgIcon, Typography, TableBody, Tooltip} from '@mui/material';
import {DIDsTableStructure, Actions} from "../constants/constants";
import {
    StyledTextField,
    StyledTableContainer,
    StyledTable,
    StyledTableHead,
    StyledTableCell,
    StyledTableRow,
    StyledTablePagination,
    StyledCircularProgress,
    StyledTablePaper,
    StyledLink,
    StyledIconButton,
    StyledAdvancedSearchClearButton,
    StyledFoundTypography,
    StyledNotFoundTypography,
    StyledNumberTypography,
    StyledSortSvgIcon,
} from "../styles/mui_styles";
import TablePaginationActions from './TablePaginationActions';
import AddDIDsPopover from './AddDIDsPopover';
import FilterPopover from './FilterPopover';
import BulkUpdatePopover from './BulkUpdatePopover';
import SuccessAlert from './SuccessAlert';
import {useLocation} from 'react-router-dom';
import DIDDetailsPopover from './DIDDetailsPopover';
import {DIDTableContext} from './Layout';
import {Link as RouterLink} from 'react-router-dom';
import AdvancedSearch from './AdvancedSearch';


export default function DIDsTable() {
    const {
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        search,
        setSearch,
        bulkSearchNumbers,
        setBulkSearchNumbers,
        sort,
        setSort,
        filters,
        setFilters,
        filterData,
        setFilterData
    } = useContext(DIDTableContext)
    const {data, isLoading, isFetching} = useGetDidsQuery({
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        number: search, ...sort, ...filters
    });
    const location = useLocation();

    useEffect(() => {
        if (Object.keys(Actions).includes(location.state)) {
            window.history.replaceState({}, document.title);
        }
    }, [location.state])

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.numbers?.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleSearch = (value) => {
        setSearch(value);
        setPage(0);
    }
    const handleSort = (column) => {
        if (data.numbers.length === 0) return;
        setSort(prev => {
            return ({
                sortField: column,
                sortType: prev.sortField === column ? (prev.sortType === 1 ? -1 : 1) : 1
            })
        });
        setPage(0);

    }

    const getSortIconClasses = (valueProperty) => {
        const arrowDirection = (sort.sortType === 1 && sort.sortField === valueProperty) && "rotate-arrow";
        const showSortedColumnArrow = sort.sortField === valueProperty && sort.sortType !== null && data.numbers.length > 0 && "showSortedColumnArrow";
        return `arrow ${arrowDirection} hide-arrow ${showSortedColumnArrow}`;
    }

    const handleAdvancedSearchClear = () => {
        setBulkSearchNumbers([]);
        setSearch("")
    }

    return (
        <StyledTablePaper>
            <Box className="container">
                <Box className="container_header_block">
                    {Object.keys(Actions).includes(location.state) &&
                        <SuccessAlert
                            action={location.state}
                        />}
                    <Box className="search_block">
                        <Tooltip
                            title={`${Array.isArray(search) ? "Basic Search is disabled during Advanced Search. Clear advanced search results to enable it again." : ""}`}
                            placement="top"
                        >
                            <StyledTextField
                                disabled={Array.isArray(search)}
                                size='small'
                                type="text"
                                placeholder='Search'
                                onChange={(event) => handleSearch(event.target.value)}
                                value={typeof search === "string" ? search : ""}
                                className="searchInput"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start"><SvgIcon
                                            className="search_icon"/></InputAdornment>),
                                    endAdornment: search && (
                                        <StyledIconButton onClick={(event) => handleSearch("")}
                                                          disabled={Array.isArray(search)}>
                                            <SvgIcon className="close_icon"/>
                                        </StyledIconButton>
                                    )
                                }}/>
                        </Tooltip>
                        <Box className="advanced_button_block">
                            <AdvancedSearch setSearch={setSearch} search={search} bulkSearchNumbers={bulkSearchNumbers}
                                            setBulkSearchNumbers={setBulkSearchNumbers}/>
                            {Array.isArray(search) && data?.numbers &&
                                <StyledAdvancedSearchClearButton variant='contained'
                                                                 onClick={handleAdvancedSearchClear}><SvgIcon
                                    className="search_clear_icon"/></StyledAdvancedSearchClearButton>}
                        </Box>
                    </Box>
                    <Box className="add_did_block">
                        <AddDIDsPopover/>
                        <FilterPopover setFilters={setFilters} filters={filters} setPage={setPage}
                                       filterData={filterData} setFilterData={setFilterData}/>
                        <BulkUpdatePopover number={search} {...sort} {...filters} />
                    </Box>
                </Box>
                <Box className={`${search?.length ? "table_block_applied_search" : "table_block"}`}>
                    {((isLoading || isFetching) && (Array.isArray(search) || !data?.numbers)) ?
                        <Box className="progress_block">
                            <StyledCircularProgress/>
                        </Box> :
                        <Box className="table_block_applied_search--separator">
                            <Box>
                                {Array.isArray(search) && data?.missingNumbers?.length ?
                                    <Box>
                                        <StyledNotFoundTypography>DIDs Not Found
                                            ({data.missingNumbers.length})</StyledNotFoundTypography>
                                        <StyledNumberTypography>{data.missingNumbers.join(", ")}</StyledNumberTypography>
                                    </Box> : null
                                }
                                {Array.isArray(search) && data?.numbers?.length ?
                                    <Box>
                                        <StyledFoundTypography>DIDs Found ({data.count})</StyledFoundTypography>
                                    </Box> : null
                                }
                            </Box>
                            {(!Array.isArray(search) || data?.numbers.length) ?
                                <Paper className={`${search?.length ? "table_paper_applied_search" : "table_paper"}`}>
                                    <StyledTableContainer>

                                        <StyledTable aria-label="custom pagination table">
                                            <StyledTableHead>
                                                <TableRow>
                                                    {DIDsTableStructure.map((item) =>
                                                        <StyledTableCell
                                                            sx={{width: "16%"}}
                                                            onClick={() => handleSort(item.valueProperty)}
                                                            key={item.columnHeader}
                                                            className={`show-arrow ${data.numbers.length === 0 ? "no-results-column" : ""}`}
                                                        >
                                                            {item.columnHeader}
                                                            <StyledSortSvgIcon
                                                                className={getSortIconClasses(item.valueProperty)}/>
                                                        </StyledTableCell>)}
                                                    <StyledTableCell key={"actions"}>Actions</StyledTableCell>
                                                </TableRow>
                                            </StyledTableHead>
                                            {data.numbers.length ?
                                                <TableBody>
                                                    {data.numbers.map((row) => (
                                                        <StyledTableRow key={row._id} className="show">
                                                            {DIDsTableStructure.map((item, i) => <StyledTableCell
                                                                className={`table_body_element ${(isLoading || isFetching) ? "data_loading" : ""}`}
                                                                key={row[item.valueProperty] + i}>
                                                                {item.columnHeader === "DID" ?
                                                                    <StyledLink component={RouterLink} to={row._id}
                                                                                underline='hover'
                                                                                className={`${(isLoading || isFetching) ? "data_loading" : ""}`}>
                                                                        {"+" + row[item.valueProperty]}
                                                                    </StyledLink> :
                                                                    row[item.valueProperty]}
                                                            </StyledTableCell>)}
                                                            <StyledTableCell>
                                                                <DIDDetailsPopover id={row._id} key={row._id}/>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                    {emptyRows > 0 && (
                                                        <TableRow>
                                                            <TableCell colSpan={6} sx={{borderBottom: "none"}}/>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                                :
                                                <Box className="empty_table_block">
                                                    <Box className="no-result"/>
                                                    <SvgIcon className="no-result-icon"/>
                                                    <Typography className="empty_table">No results found</Typography>
                                                </Box>
                                            }
                                        </StyledTable>

                                    </StyledTableContainer>
                                    <StyledTablePagination
                                        component="div"
                                        labelRowsPerPage="Items per page:"
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        colSpan={12}
                                        count={data?.count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </Paper> : null
                            }
                        </Box>
                    }
                </Box>
            </Box>
        </StyledTablePaper>
    )
}


