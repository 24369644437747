
import { AddNewDIDFields, propertiesNotToBeEditted, faxBSData } from "../constants/constants";

export const didDataProcessor = {
    getOptions: function (didData, name, partnersData, customersData) {
        if (name === "partner") {
            return partnersData ? partnersData.map(this.mapOptions) : [];
        }
        if (name === "customer") {
            return customersData ? customersData.map(this.mapOptions) : [];
        }
        if (name === "state") {
            return AddNewDIDFields[name].options(didData.country.id).map(this.mapOptions);
        }
        if (name === "services") {
            return ["Fax", "MMS", "SMS"].map(this.mapOptions);
        }
        return AddNewDIDFields[name].options().map(this.mapOptions);
    },
    mapOptions: function (option) {
        let newOption = {};
        if (typeof option === 'string') {
            newOption.id = option;
            newOption.label = option;
        }
        if (typeof option === 'object') {
            newOption = JSON.parse(JSON.stringify(option));
            newOption.id = option._id || option.abbreviation;
            newOption.label = option.abbreviation || 
                (option.status === "Canceled" || option.status === "Disabled" ? option.name + " (disabled)" : option.name) + (option.BSGroupId ? ` | ${option.BSGroupId}` : "");
        }
        return newOption;
    },
    prepareRequestBody: function (didData, action) {
        const dataToSend = JSON.parse(JSON.stringify(didData));
        dataToSend.number = `1${dataToSend.number}`;
        for (const key in didData) {
            if (typeof didData[key] === "object" && "id" in didData[key]) {
                if (key === "partner" || key === "customer") {
                    dataToSend[key + (action === "edit" ? "ID" : "")] = action === "edit" ? didData[key].id : didData[key].name;
                } else {
                    dataToSend[key] = didData[key].id;
                }
            }
            if (["sms", "mms", "fax"].includes(key)) {
                dataToSend[key] = didData[key] ? "Enabled" : "Disabled";
            }
        }
        ["city", "rateCenter"].forEach(key => {
            if (dataToSend[key]) {
                const wordsInValue = dataToSend[key].split(" ");
                dataToSend[key] = wordsInValue.map(word => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
            }
        });
        if (action === "edit") {
            delete dataToSend.customer;
            delete dataToSend.partner;
            propertiesNotToBeEditted.forEach(prop => {
                delete dataToSend[prop];
            })
        }
        return dataToSend;
    },
    checkIfRequired: function (didData, name) {
        return name === "partner" ?
            didData.status.id === "Reserved" || didData.status.id === "Assigned" || didData.fax:
            name === "customer" ? 
            didData.fax :
            name === "smsMmsProvider" && (didData?.sms || didData?.mms) ?
            true :
            ["rateCenter", "country", "state"].includes(name) && (didData.type.id === "Toll-free" || didData.type.id === "Pseudo") ?
            false :
            // ["BSEnterpriseName", "BSEnterpriseId", "BSGroupName", "BSGroupId"].includes(name) && (didData.customer.id || didData.fax) ?
            // true :
            AddNewDIDFields[name].required;
    },
    checkIfDisabled: function (didData, name, action) {
        return name === "partner" && didData.status.id === "Available" ? true :
                name=== "customer" && action !== "filter" && (!didData.partner.id || didData.status.id === "Available") ? true :
                name === "state" && !didData?.country.id ? true : 
                name === "smsMmsProvider" && !(didData?.sms || didData?.mms) ? true : 
                name === "fax" && didData.status.id === "Available" ? true : 
                action !== "filter" && ["BSEnterpriseName", "BSEnterpriseId", "BSGroupName", "BSGroupId"].includes(name) ? true :
                action === "edit" ? propertiesNotToBeEditted.includes(name):
                false;
    },
    validateManualChanges: function(data, didData, didExternalData, didExternalDataErrors, action) {
        let valid = true;
        const errors = JSON.parse(JSON.stringify(didExternalDataErrors));
        const npa_nxx = errors.find(item => item.property === "npa_nxx");
        npa_nxx.value = didData.number.slice(0, 3) + "-" + didData.number.slice(3, 6);
        if (action !== "edit" && !didExternalData.found) {
            npa_nxx.invalid = true;
            valid = false;
            return {valid, errors};
        }
        
        didExternalDataErrors.forEach((item, i) => {
            const key = item.property;
            const ignoreCase = key === "city" || key === "rateCenter";
            const dbValue = action === "edit" ? (ignoreCase ? data[key]?.toLowerCase() || "" : data[key]) : "";
            const originalValue = typeof didData[key] === "object" ? didData[key].id : didData[key];
            const value = ignoreCase ? originalValue.toLowerCase() : originalValue;
            const didExternDataValue = ignoreCase ? didExternalData[key].toLowerCase() : didExternalData[key];
            const invalidCondition = key !== "npa_nxx" && didExternDataValue && value !== didExternDataValue && (action === "edit" ? value !== dbValue : true);

            if (invalidCondition) {
                errors[i].invalid = true;
                errors[i].value = originalValue;
                valid = false;
            }
        })
        return {valid, errors};
    },
    getDidData: function(data) {
        const getPartnerCustomerData = (field) => {
            const fieldData = JSON.parse(JSON.stringify(data[field]));
            fieldData.id = fieldData._id || "";
            fieldData.label = fieldData.name ? 
                fieldData.status === "Canceled" || fieldData.status === "Disabled" ? `${fieldData.name} (disabled)` : fieldData.name :
                "";
            return fieldData;
        }
        const {broadSoftInfo} = data;
        return {
            number: `${data.number}`.slice(1),
            status: {id: data.status, label: data.status},
            carrier: {id: data.carrier, label: data.carrier},
            type: {id: data.type, label: data.type},
            rateCenter: data.rateCenter || "",
            portStatus: {id: data.portStatus ? data.portStatus : "", label: data.portStatus ? data.portStatus : ""},
            country: {id: data.country, label: data.country},
            state: {id: data.state || "", label: data.state || ""},
            city: data.city ? data.city : "",
            partner: getPartnerCustomerData("partner"),
            customer: getPartnerCustomerData("customer"),
            sms: data.sms === "Enabled",
            mms: data.mms === "Enabled",
            smsMmsProvider: {id: data.smsMmsProvider ? data.smsMmsProvider : "", label: data.smsMmsProvider ? data.smsMmsProvider : ""},
            fax: data.fax === "Enabled",
            BSEnterpriseName: broadSoftInfo.BSEnterpriseName ? broadSoftInfo.BSEnterpriseName : "",
            BSEnterpriseId: broadSoftInfo.BSEnterpriseId ? broadSoftInfo.BSEnterpriseId : "",
            BSGroupName: broadSoftInfo.BSGroupName ? broadSoftInfo.BSGroupName : "",
            BSGroupId: broadSoftInfo.BSGroupId ? broadSoftInfo.BSGroupId : "",
        }
    },
    setBSData: function(prevData, newData) {
        ["BSEnterpriseName", "BSEnterpriseId", "BSGroupName", "BSGroupId"].forEach(key => {
            if (newData) {
                if (newData === "fax") {
                    prevData[key] = faxBSData[key];
                } else {
                    prevData[key] = newData[key];
                }
            } else {
                prevData[key] = "";
            }
        });
        return prevData;
    }
}