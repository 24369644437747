import React, {useState, useEffect} from 'react';
import {Box, IconButton, Slide }from '@mui/material';
import {StyledAlert}from "../styles/mui_styles";
import CloseIcon from '@mui/icons-material/Close';
import { Actions } from '../constants/constants';

export default React.memo(function SuccessAlert({action}) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (action !== "bulk_import_success" && action !== "bulk_update_success") {
      setTimeout(() => {
        setOpen(false); 
    }, 4000);
    }
  }, [])

  return (
      open &&
      <Box className="alert_block">
      <Slide in={open} direction="left" timeout={800}>
        <StyledAlert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {Actions[action].message}
        </StyledAlert>
      </Slide>
    </Box>
  );
})
