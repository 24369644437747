import React, {useState} from 'react'
import { Box} from '@mui/material';
import {StyledMenuItem, StyledBorderedButton,StyledPopover, StyledAddList} from "../styles/mui_styles";
import {ArrowDropDown} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function AddDIDsPopover() {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleAddDids = (to) => {
        navigate(to);
        handleClose();
    }

  return (
    <Box>
        <StyledBorderedButton
            size='medium'
            variant="contained"
            aria-describedby={id}
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
            >
            Add DID
        </StyledBorderedButton>
        <StyledPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <StyledAddList>
                <StyledMenuItem onClick={() => handleAddDids('new_did')}>
                    Add new DID
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleAddDids('bulk_import')}>
                    Import from Excel
                </StyledMenuItem>
            </StyledAddList>
        </StyledPopover>
    </Box>
  )
}

export default AddDIDsPopover;
