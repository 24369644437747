import React, {useState, useEffect} from 'react';
import {Box, Typography, SvgIcon} from '@mui/material';
import {
    StyledPaper,
    StyledStepper,
    StyledStep,
    StyledStepLabel,
    QontoConnector,
    StyledNextButton,
    StyledBackButton
} from "../../styles/mui_styles";

import { useMediaQuery } from '@mui/material';
import ExceptionsPopover from './ExceptionsPopover';
import SyncDataStepOne from './SyncDataStepOne';
import SyncDataStepTwo from './SyncDataStepTwo';
import SyncDataStepThree from './SyncDataStepThree';
import {useSyncProviderDataMutation} from '../../services/requests';

const steps = ['Choose provider', 'Upload file / Fetch data automatically', 'Compare data'];

function SyncData() {
  const [activeStep, setActiveStep] = useState(0);
  const [carrier, setCarrier] = useState({id: "", label: ""});
  const [file, setFile] = useState(null)
  const [errors, setErrors] = useState(false);

  const [syncProviderDataRequest, syncProviderDataResponse] = useSyncProviderDataMutation();

  const smallScreen = useMediaQuery("(max-width: 768px)");
  const handleNavigation = (btn) => {
    if (btn === "back" && activeStep === 1) {
      setFile(null);
      resetErrors();
    }
    if (btn === "next" && activeStep === 1) {
      const dataToSend = new FormData();
      dataToSend.append("file", file)
      const valid = isValid()
      syncProviderDataRequest({
        provider: carrier.label, 
        file: dataToSend, 
        validate: !valid && !hasAPI(carrier.id)
      });
      if (!valid && !hasAPI(carrier.id)) return;
    }
    if (btn === "next" && activeStep >= 2) {
      setActiveStep(0);
      setCarrier({id: "", label: ""});
      setFile(null);
      resetErrors();
      return;
    }
    setActiveStep((prevActiveStep) => {
      if (btn === "next" && prevActiveStep === steps.length - 1) return prevActiveStep;
      return btn === "next" ? prevActiveStep  + 1 : prevActiveStep  - 1;
    });
  };


const checkIfNextIsDisabled = () => {
  if (activeStep === 0) return !carrier.id;
  if (activeStep === 1) return !hasAPI(carrier.id) && (!file || (file && errors) || syncProviderDataResponse.isLoading);
  if (activeStep === 2) return syncProviderDataResponse.isLoading;
  return false;
}

const resetErrors = () => {
  setErrors(false);
  syncProviderDataResponse.reset();
}

const hasAPI = (name) => {
  return ["Bandwidth", "Inteliquent"].includes(name);
}

const isValid =() => {
  return syncProviderDataResponse.data?.valid
}

useEffect(() => {
  if (syncProviderDataResponse.isError) {
    setErrors(true);
  }
}, [syncProviderDataResponse.isError])

useEffect(() => {
  if (syncProviderDataResponse.isSuccess) {
    const resp = syncProviderDataResponse.data
    if (resp.valid) {
      handleNavigation("next");
    }
    if (resp.hasOwnProperty("link")) {
      setActiveStep(prev => prev + 1)
    }
  }
}, [syncProviderDataResponse.isSuccess])

  return (
    <>
    <StyledPaper className="exception_section">
      <Box className="exception_block">
        <Box className="exception_header">
          <Box className="devider">
            <SvgIcon className="icon_info"/>
            <Typography className="exception_title">
                In this section of the system, you can perform a comparison between the DID Management
                System database and databases from different providers to identify any inconsistencies or
                mismatching data between the two systems. Follow the three-step process outlined below to get started.
            </Typography>
          </Box>
          <ExceptionsPopover/>
        </Box>
        <StyledStepper
            orientation={smallScreen ? "vertical" : "horizontal"}
            sx={{
              '.MuiStepIcon-text': {fill: '#9e9e9e'},
              '.MuiStepIcon-root': {color: '#eaeaf0'},
              '.MuiStepIcon-root.Mui-active': {
                  color: '#80a1fd',
                  '.MuiStepIcon-text': {fill: '#fff'},
              },
              '.MuiStepIcon-root.Mui-completed': {
                  color: '#80a1fd',
                  '.MuiStepIcon-text': {fill: '#fff'},
              },
          }}
          className="stepper" activeStep={activeStep} connector={<QontoConnector/>}>
          {steps.map((label, index) => {
              return (
                  <StyledStep key={label}>
                      <StyledStepLabel>{label}</StyledStepLabel>
                  </StyledStep>
              );
          })}
        </StyledStepper>
        <Box className="content_grid">
          { activeStep === 0 && 
            <Box className="content_grid_child">
              <SyncDataStepOne carrier={carrier} setCarrier={setCarrier}/>
            </Box>
          }
          { activeStep === 1 && 
            <Box className="content_grid_child">
              <SyncDataStepTwo 
                carrier={carrier} 
                file={file} 
                setFile={setFile} 
                loading={syncProviderDataResponse.isLoading}
                errors={errors} 
                errorsData={errors && syncProviderDataResponse.error.data.error.message}
                resetErrors={resetErrors}
                hasAPI={hasAPI}
              />
            </Box>
          }
          { activeStep > 1 && 
            <Box className="content_grid_child">
              <SyncDataStepThree 
                carrier={carrier} 
                loading={syncProviderDataResponse.isLoading} 
                link={syncProviderDataResponse.data?.link}
                error={errors}
                success={syncProviderDataResponse.isSuccess}
              />
            </Box>
          }
        </Box>
      </Box>
    </StyledPaper>
    <Box className="button_box">
      {activeStep === 1 && <StyledBackButton
        color="inherit"
        onClick={() => handleNavigation("back")}
        sx={{mr: 1}}
      >
        Back
      </StyledBackButton>}
      <StyledNextButton
        onClick={() => handleNavigation("next")}
        disabled={checkIfNextIsDisabled()}
      >
        {activeStep > 1 ? 'Back to Main Page' : 'Next'}
      </StyledNextButton>
    </Box>
    </>
  )
}

export default SyncData;
