import React, {useEffect, useState, createContext}from 'react'
import {Box, CssBaseline, SvgIcon, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {useNavigate, Outlet, useLocation} from "react-router-dom";
import {StyledDrawer, DrawerHeader, StyledListItemButton, StyledIconButton, StyledList} from '../styles/mui_styles';
import GlobalHeader from './GlobalHeader';
import {didInitialStateForFilters} from "../constants/constants";

const pages = [
    {name: 'DIDs', icon: <SvgIcon className="phone_icon"/>, to: "/dids"},
    {name: 'Exceptions', icon: <SvgIcon className="paper_icon"/>, to: "/exceptions"}
]

export const DIDTableContext = createContext(null);

function Layout({classes, ...other}) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [search, setSearch] = useState(null);
    const [sort, setSort] = useState({sortField: "", sortType: null})
    const [filters, setFilters] = useState(null)
    const [filterData, setFilterData] = useState(didInitialStateForFilters);
    const [bulkSearchNumbers, setBulkSearchNumbers] = useState("")

    useEffect(() => {
        const page = location.pathname.length > 1 ? location.pathname : pages[0].to;
        navigate(page)
    }, [])

    const handleDrawerOpen = () => {
        setOpen(prev => !prev);
    };

    const clearDIDFilters = () => {
        setPage(0);
        setRowsPerPage(25);
        setSearch(null);
        setBulkSearchNumbers("")
        setSort({sortField: "", sortType: null});
        setFilters(null);
        setFilterData(didInitialStateForFilters);
    }
    return (
        <Box className="layout">
            <CssBaseline/>
            <StyledDrawer variant="permanent" open={open} PaperProps={{elevation: 4}}>
                <DrawerHeader>
                    <StyledIconButton onClick={handleDrawerOpen}>
                        <SvgIcon className="menu_icon"/>
                    </StyledIconButton>
                </DrawerHeader>
                <StyledList>
                    {pages.map((item, index) => (
                        <ListItem key={item.name} disablePadding sx={{display: 'block'}} selected={location.pathname.includes(item.to)}>
                            <StyledListItemButton
                                onClick={() => {
                                    navigate(item.to);
                                    setOpen(false)
                                }}
                            >
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText className="list_name" primary={item.name} sx={{opacity: open ? 1 : 0}}/>
                            </StyledListItemButton>
                        </ListItem>
                    ))}
                </StyledList>
            </StyledDrawer>
            <Box sx={{flexGrow: 1}}>
                <GlobalHeader clearDIDFilters={clearDIDFilters}/>
                <DIDTableContext.Provider 
                    value={{
                        page, 
                        setPage, 
                        rowsPerPage, 
                        setRowsPerPage, 
                        search, 
                        setSearch,         
                        bulkSearchNumbers,
                        setBulkSearchNumbers, 
                        sort, 
                        setSort, 
                        filters, 
                        setFilters, 
                        filterData, 
                        setFilterData
                    }}
                >
                    <Outlet/>
                </DIDTableContext.Provider>
            </Box>
        </Box>
    )
}

export default Layout;
