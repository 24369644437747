import {TableBody, Typography, TableRow} from '@mui/material';
import {
    StyledHistoryTableContainer,
    StyledTable,
    StyledTableHead,
    StyledTableCell,
    StyledTableRow,
    StyledHistoryPaper
} from "../styles/mui_styles";
import {getFormattedDate} from "../utils/utils";
import { HistoryTableStructure } from "../constants/constants"

function DIDHistory({data}) {
    // const orderArray = (arr) => {
    //     let newArr =[];
    //     const number = arr.find(item => item.changedField === "number");
    //     if (number) {
    //         newArr.push(number);
    //     }
    //     newArr = newArr.concat(arr.filter(item => item.changedField !== "number" && item.changedField !== "customer" && item.changedField !== "partner"))
    //     return newArr;
    // }
    return (
        <StyledHistoryPaper>
            <StyledHistoryTableContainer>
                <StyledTable aria-label="custom pagination table">
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell>Action date</StyledTableCell>
                            <StyledTableCell>Action by</StyledTableCell>
                            <StyledTableCell>Action type</StyledTableCell>
                            <StyledTableCell>Field</StyledTableCell>
                            <StyledTableCell>Original Value</StyledTableCell>
                            <StyledTableCell>New Value</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    {data?.length > 0 &&
                    <TableBody>
                        {data.map(arr => {
                           return arr.map(item => {
                            return item.changedField !== "areaCode" && item.changedField !== "countryCode" &&
                            <StyledTableRow key={item._id}>
                                    <StyledTableCell>{getFormattedDate(item.createdAt)}</StyledTableCell>
                                    <StyledTableCell>{item.changedBy.email}</StyledTableCell>
                                    <StyledTableCell>{item.action}</StyledTableCell>
                                    <StyledTableCell>{HistoryTableStructure[item.changedField]}</StyledTableCell>
                                    <StyledTableCell>
                                        {
                                            (item.changedField === "customerID" || item.changedField === "partnerID") && item.oldValue ? 
                                            item.oldValue.name : 
                                            item.changedField === "number" &&  item.oldValue ?
                                            "+" + item.oldValue :
                                            item.oldValue
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {
                                            (item.changedField === "customerID" || item.changedField === "partnerID") && item.newValue ? 
                                            item.newValue.name : 
                                            item.changedField === "number" && item.newValue ?
                                            "+" + item.newValue :
                                            item.newValue
                                        }
                                    </StyledTableCell>
                            </StyledTableRow>
                            })
                        })}
                    </TableBody>
                    }
                </StyledTable>
            </StyledHistoryTableContainer>
        </StyledHistoryPaper>
    )
}

export default DIDHistory;
