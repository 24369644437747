import React, {useEffect, useRef, forwardRef, useState} from 'react';
import {Box, TextField, SvgIcon, Tooltip, Chip, Popper} from '@mui/material';
import {
    StyledFilterAutocomplete,
    StyledInputLabel,
    StyledFormHelperText,
    StyledSyncIconButton,
    StyledSyncIcon,
} from '../styles/mui_styles';
import {AddNewDIDFields} from "../constants/constants";
import {useSyncPartnersCustomersMutation} from '../services/requests';
import {useInView} from 'react-intersection-observer';

function AddNewDIDSelectField({
                                  required,
                                  optionsStr,
                                  name,
                                  value,
                                  handleChange,
                                  errorsStr,
                                  disabled,
                                  multiple,
                                  loading,
                                  setCustomersToSkip,
                                  setCustomerValue,
                                  setCustomersCache,
                                  customerValue,
                                  customersToSkip,
                                  setSkipCustomersRequest,
                                  skipCustomersRequest
                              }) {
    const options = JSON.parse(optionsStr);
    // const value = JSON.parse(valueStr);
    const prevValue = usePrevious(value);
    const errors = errorsStr ? errorsStr.split(",") : null;

    const [chipTooltips, setChipTooltips] = useState([]);
    const [valueTooltip, setValueTooltip] = useState(false);

    const [syncPartnerCustomerRequest, syncPartnerCustomerResponse] = useSyncPartnersCustomersMutation();

    const handleSync = (e) => {
        e.preventDefault();
        e.stopPropagation()
        const newVal = multiple ? [] : {id: "", label: ""};
        handleChange("partner", newVal);
        handleChange("customer", newVal);
        syncPartnerCustomerRequest(value.id);
    }

    useEffect(() => {
    }, [syncPartnerCustomerResponse.isSuccess])

    function addSyncIconToEndAdornment(endAdornment) {
        const children = React.Children.toArray(endAdornment.props.children);
        const arrow = children.pop();
        const syncIcon = (
            <Tooltip title="Get the latest partner and customer list from Logisense." placement="top" arrow
                     key="sync_tooltip">
                <StyledSyncIconButton size="small" onClick={(e) => handleSync(e)} disabled={disabled}>
                    <StyledSyncIcon className={`${syncPartnerCustomerResponse.isLoading ? "sync_in_progress" : ""}`}/>
                </StyledSyncIconButton>
            </Tooltip>)
        children.push(syncIcon);
        children.push(arrow)
        return (<div
            className={`${endAdornment.props.className}`}
            ownerState={endAdornment.props.ownerState}
            style={{top: "calc(50% - 14px)", position: "absolute"}}
        >
            {children}
        </div>);
    }

    const getDisabledPartnerCustomerClass = (value) => {
        return (name === "partner" || name === "customer") && value.label.includes('(disabled)') ? 'disabledPartnerCustomer' : "";
    }
    const PopperMy = function (props) {
        return (<Popper {...props} style={{width: "-webkit-fill-available"}} placement='bottom-start'/>)
    }

    const setInputRef = (node) => {
        let timeout;
        const handleWindowResize = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => handleSetOptionTooltips(node), 700);
        }
        if (node) {
            handleSetOptionTooltips(node)
            window.addEventListener("resize", handleWindowResize);
        } else {
            window.removeEventListener("resize", handleWindowResize);
        }
    }

    const handleSetOptionTooltips = (node) => {
        const {clientWidth, scrollWidth} = node;
        if (scrollWidth > clientWidth) {
            setValueTooltip(true);
        } else {
            setValueTooltip(false);
        }
    }

    return (
        <Box className="add_did_block">
            <StyledInputLabel>
                {name === "services" ? "Services" : AddNewDIDFields[name].label}
                {required && <span className="required_asterisk">*</span>}
            </StyledInputLabel>
            <StyledFilterAutocomplete
                // PopperComponent={PopperMy}
                // disablePortal
                disableListWrap
                multiple={multiple}
                disabled={disabled}
                noOptionsText="No options"
                loadingText={<Box className="loading_icon_block"><SvgIcon className="loading_icon"/></Box>}
                loading={(name === "customer" || name === "partner") && loading}
                // open={name === "state" && true}
                value={value}
                popupIcon={<SvgIcon className="drop_down_arrow"/>}
                onChange={(event, newValue, reason) => {
                    const newVal = reason === "clear" && multiple ? [] : reason === "clear" ? {
                        id: "",
                        label: ""
                    } : newValue;
                    handleChange(name, newVal);
                }}
                onInputChange={(event, newValue, reason) => {
                    if (name === "customer" && reason !== "reset") {
                        setCustomersToSkip(0);
                        setCustomerValue(newValue || "");
                        if (reason === "clear") {
                            if (customerValue || customersToSkip) {
                                setCustomersCache([]);
                            }
                        } else {
                            setCustomersCache([]);
                        }
                        setSkipCustomersRequest(false);
                    }
                }}
                onClose={(event, reason) => {
                    if (name === "customer") {
                        if (customerValue || customersToSkip) {
                            setCustomersCache([]);
                        }
                        setCustomersToSkip(0);
                        setCustomerValue("");
                        setSkipCustomersRequest(false)
                    }
                }}
                componentsProps={{
                    paper: {
                        sx: {
                            fontSize: "12px",
                            "& ul": {
                                maxHeight: "20vh"
                            },
                        },
                    },
                }}
                ChipProps={{deleteIcon: <SvgIcon className="cross_icon"/>}}
                options={options}
                isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id;
                }}
                getOptionLabel={(option) => {
                    return Array.isArray(option) && option.length === 0 ? "" : typeof option === "object" ? option.label : option;
                }}
                renderOption={(props, option, state) => {
                    return (
                        <li  {...props} key={option._id || option.id}
                             style={{
                                 display: 'block',
                                 textOverflow: 'ellipsis',
                                 overflow: "hidden",
                                 whiteSpace: "nowrap",
                             }}
                             className={props.className + " " + getDisabledPartnerCustomerClass(option)}>
                            {option.label}
                        </li>
                    )
                }}
                filterOptions={(options, state) => {
                    if (name === "customer") return options;
                    return options.filter(option => {
                        const lowerLabel = option.label.toLowerCase();
                        const lowerInput = state.inputValue?.toLowerCase();
                        return lowerLabel.includes(lowerInput) && option;
                    })
                }}
                ListboxComponent={CustomListbox}
                ListboxProps={{setCustomersToSkip, skipCustomersRequest, loading, name, multiple, style: {maxHeight: '15rem'}}}
                blurOnSelect={true}
                renderInput={(params) => {
                    return (
                        <Tooltip
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-arrow': {
                                        color: 'rgb(58 57 57 / 94%)',
                                    }
                                }
                            }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: 'rgb(58 57 57 / 94%)',
                                    },
                                },
                            }}
                            title={valueTooltip ? value.label : ""} placement="top" arrow>
                            {name === "customer" ? <TextField
                                    error={errors && errors.length > 0}
                                    required={required}
                                    {...params}
                                    value={customerValue}
                                    size="small"
                                    placeholder="Choose"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: params.InputProps.endAdornment
                                    }}
                                    inputRef={(node) => setInputRef(node)}
                                /> :
                                <TextField
                                    error={errors && errors.length > 0}
                                    required={required}
                                    {...params}
                                    size="small"
                                    placeholder="Choose"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: name === "partner" ?
                                            addSyncIconToEndAdornment(params.InputProps.endAdornment) :
                                            params.InputProps.endAdornment
                                    }}
                                    inputRef={(node) => setInputRef(node)}
                                />}
                        </Tooltip>
                    )
                }}
                renderTags={(val, getTagProps) => {
                    return val.map((option, index) => {
                        const props = getTagProps({index});
                        return <Tooltip
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-arrow': {
                                        color: 'rgb(58 57 57 / 94%)',
                                    }
                                }
                            }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: 'rgb(58 57 57 / 94%)',
                                    },
                                },
                            }}
                            title={chipTooltips[index] ? option.label : ""} placement="top" arrow
                            key={option._id || option.id}>
                            <Chip
                                key={option.id}
                                label={option.label}
                                {...props}
                                className={props.className + " " + getDisabledPartnerCustomerClass(option)}
                                deleteIcon={<SvgIcon className="cross_icon"/>}
                                ref={(node) => {
                                    if (node) {
                                        const {clientWidth, scrollWidth} = node.firstElementChild;
                                        if (prevValue?.toString() !== value.toString()) {
                                            setChipTooltips(prev => {
                                                const newVal = [...prev]
                                                newVal[index] = scrollWidth > clientWidth ? true : false;
                                                return newVal;
                                            })
                                        }
                                    }
                                }}
                            />
                        </Tooltip>
                    })
                }}

                classes={{input: !multiple && getDisabledPartnerCustomerClass(value)}}
            />

            {errors && errors.map(element => <StyledFormHelperText key={element}>{element}</StyledFormHelperText>)}
        </Box>

    )

}

const CustomListbox = forwardRef(function CustomListbox({
                                                            children,
                                                            name,
                                                            multiple,
                                                            setCustomersToSkip,
                                                            skipCustomersRequest,
                                                            loading,
                                                            ...props
                                                        }, parentRef) {
    const root = useRef(null)
    const [optionTooltips, setOptionTooltips] = useState([]);

    const {ref} = useInView({
        threshold: 0,
        root: root.current,
        onChange: (inView) => {
            const rootHasScrollBar = root.current.scrollHeight > root.current.clientHeight;
            if (inView && !loading && rootHasScrollBar && !skipCustomersRequest) {
                setCustomersToSkip(prev => prev + 20)
            }
            ;
        }
    });

    useEffect(() => {
        let timeout;
        const handleWindowResize = () => {
            clearTimeout(timeout);
            timeout = setTimeout(handleSetOptionTooltips, 300);
        }
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    }, [])

    useEffect(() => {
        if (root.current) {
            if (name === "customer") {
                const lastChild = root.current.children[root.current.children.length - 4]
                if (root.current.children.length > 21) {
                    lastChild.scrollIntoView({block: "end"});
                }
            }
            handleSetOptionTooltips();
        }
    }, [children])

    const handleSetOptionTooltips = () => {
        if (root.current) {
            let optionTooltips = [];
            Array.from(root.current.children).forEach((node, i) => {
                const {clientWidth, scrollWidth} = node;
                optionTooltips[i] = {overflows: scrollWidth > clientWidth, label: node.innerText};
            })
            setOptionTooltips(optionTooltips);
        }
    }

    return (
        <div ref={parentRef}>
            <ul {...props} ref={root}>
                {children.map((child, i) => {
                    return <Tooltip
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-arrow': {
                                    color: 'rgb(58 57 57 / 94%)',
                                }
                            }
                        }}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    backgroundColor: 'rgb(58 57 57 / 94%)',
                                },
                            },
                        }}
                        key={child.key} title={optionTooltips[i]?.overflows ? optionTooltips[i]?.label : ""}
                        placement="top" arrow>{child}</Tooltip>;
                })}
                {name === "customer" &&
                    <>
                        <div ref={ref} key="myRef" style={{height: 5, width: "100%"}}></div>
                        {loading && <Box className="loading_icon_block"><SvgIcon className="loading_icon"/></Box>}
                    </>}
            </ul>
        </div>
    )
})

function usePrevious(value) {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default React.memo(AddNewDIDSelectField);
