import React, {useRef, useEffect, useState} from 'react'
import {Box, Typography, SvgIcon, CircularProgress} from "@mui/material";
import {
    StyledDownloadButton,
    StyledNextButton,
    StyledBulkActionPaper,
    StyledUploadButton,
    StyledLeaveButton
} from "../styles/mui_styles";
import {useNavigate} from "react-router-dom";
import Bulk_import_template from "../assets/Bulk_import_template.xlsx";
import ErrorAlert from "./ErrorAlert";
import {bulkImportMockErrors} from "../mock";
import {useBulkAddDidMutation, useBulkUpdateDidMutation} from '../services/requests';
import LeavePageDialog from './LeavePageDialog';
import {useLocation} from 'react-router-dom';

function BulkImportUpdateDIDs() {
    const location = useLocation();
    const pathsArray = location.pathname.split('/');
    const action = pathsArray[2];
    const navigate = useNavigate();
    const dragArea = useRef(null);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [file, setFile] = useState(null)
    const [dragging, setDragging] = useState(false);
    const [errors, setErrors] = useState(false);
    const [buttonText, setButtonText] = useState(action === "bulk_import" ? "Import" : "Update");

    const [bulkAddDIDRequest, bulkAddDIDResponse] = useBulkAddDidMutation();
    const [bulkUpdateDIDRequest, bulkUpdateDIDResponse] = useBulkUpdateDidMutation();

    const resetErrors = () => {
        setErrors(false);
        setButtonText(action === "bulk_import" ? "Import" : "Update")
        if (action === "bulk_import") {
            bulkAddDIDResponse.reset();
        } else {
            bulkUpdateDIDResponse.reset();
        }
    }

    useEffect(() => {
        const elem = dragArea.current;
        if (elem) {
            ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
                dragArea.current.addEventListener(eventName, preventDefaults, false)
            })
            elem.addEventListener('drop', handleDrop, false)

        }

        function handleDrop(ev) {
            setDragging(false)
            ev.preventDefault();
            ev.stopPropagation();

            if (ev.dataTransfer.files) {
                const files = [...ev.dataTransfer.files];
                if (files.length > 1) return;
                const file = files[0];
                const splitFileName = file.name.split(".")
                const fileType = splitFileName[splitFileName.length - 1];
                if (!["xls", "xlsx"].includes(fileType)) {
                    return;
                }
                resetErrors();
                if (file.size > 10000000) {
                    setFileSizeError(true);
                    return;
                } else {
                    setFileSizeError(false);
                }
                setFile(file);
            }
        }

        function preventDefaults(e) {
            if (e.type === "dragover" && dragging === false) {
                setDragging(true)
            }
            if (e.type === "dragleave") {
                setDragging(false)
            }
            e.preventDefault()
            e.stopPropagation()
        }

        return () => {
            if (elem) {
                ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
                    elem.removeEventListener(eventName, preventDefaults, false);
                    elem.removeEventListener('drop', handleDrop, false);
                })
            }
        }
    }, [dragArea])


    const handleFileUpload = (event) => {
        event.preventDefault();
        event.stopPropagation();
        resetErrors();
        const files = [...event.target.files];
        const file = files[0];
        console.log(file.size)
        if (file.size > 10000000) {
            setFileSizeError(true);
            return;
        } else {
            setFileSizeError(false);
        }
        setFile(file);
    }

    const handleFileRemove = () => {
        resetErrors();
        setFile(null);
        setFileSizeError(false);
    }

    const getFileSize = (sizeInBytes) => {
        const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(5);
        return `${sizeInMB}MB`;
    }

    const handleSubmit = () => {
        if (!file) return;
        if (fileSizeError) return;
        if (errors && !buttonText.includes("Confirm")) return;
        setErrors(false);
        const dataToSend = new FormData();
        dataToSend.append("uploadFile", file)
        const body = {file: dataToSend, validateNpaNxx: buttonText.includes("Confirm") ? false : true}
        if (action === "bulk_import") {
            bulkAddDIDRequest(body);
        } else {
            bulkUpdateDIDRequest(body);
        }
    }

    useEffect(() => {
        if (bulkAddDIDResponse.isSuccess) {
            navigate("/dids", {replace: true, state: 'bulk_import_success'});
        }
    }, [bulkAddDIDResponse.isSuccess])

    useEffect(() => {
        if (bulkUpdateDIDResponse.isSuccess) {
            navigate("/dids", {replace: true, state: 'bulk_update_success'});
        }
    }, [bulkUpdateDIDResponse.isSuccess])

    useEffect(() => {
        if (bulkAddDIDResponse.isError) {
            setErrors(true);
            handleSetButtonText();
        }
    }, [bulkAddDIDResponse.isError])

    useEffect(() => {
        if (bulkUpdateDIDResponse.isError) {
            setErrors(true);
            handleSetButtonText();
        }
    }, [bulkUpdateDIDResponse.isError])

    const getErrors = (data) => {
        const dataToReturn = []
        data.forEach(element => {
            if (Array.isArray(element)) {
                element.forEach(item => {
                    if ((item.type === "invalid_data" && item.location.some(row => row.value)) || item.type !== "invalid_data") {
                        dataToReturn.push(item);
                    }
                });
            } else {
                if ((element.type === "invalid_data" && element.location.some(row => row.value)) || element.type !== "invalid_data") {
                    dataToReturn.push(element);
                }
            }
        });
        return dataToReturn;
    }

    const handleSetButtonText = () => {
        const actionResponse = action === "bulk_import" ? bulkAddDIDResponse : bulkUpdateDIDResponse;
        let warning = false;
        if ((Array.isArray(actionResponse.error.data?.error?.message))) {
           warning = getErrors(actionResponse.error.data.error.message).some(error => error.type.includes("warning"));
        }
        const text = `${warning ? "Confirm & " : ""} ${action === "bulk_import" ? "Import" : "Update"}`;
        setButtonText(text);
    }

    return (
        <Box className="excel_block">

            <StyledBulkActionPaper className={action}>
                <Box className={`container_main`}>
                    <Box className="container_main_separator">
                        <Box className="template_header">
                            <Typography
                                className="file_title">{`${action === "bulk_update" ? "Bulk update" : "Import"} DIDs via Excel file`}</Typography>
                            <Box className="note_block">
                                <SvgIcon className="note_icon"/>
                                <Typography className="file_span">Only files with .xls and .xlsx extensions are
                                    allowed.</Typography>
                            </Box>
                        </Box>
                        {action === "bulk_import" &&
                        <StyledDownloadButton component="a" download="Bulk import DIDs template" href={Bulk_import_template}>
                            Download Template
                        </StyledDownloadButton>
                        }
                    </Box>
                    <Box ref={dragArea} className={`drag_drop_block ${dragging ? "dragging" : ""}`}>
                        {
                            !file
                                ?
                                <>
                                    <SvgIcon className="drag_drop_icon"/>
                                    <Typography className="drag_drop_title">Drop your file here</Typography>
                                    <Typography className="drag_drop_title">or</Typography>
                                    <label htmlFor="upload-photo">
                                        <input
                                            className="upload_type"
                                            id="upload-photo"
                                            name="upload-photo"
                                            type="file"
                                            accept=".xls,.xlsx"
                                            onChange={(event) => handleFileUpload(event)}
                                            onClick={(event) => event.target.value = null}
                                        />
                                        <StyledUploadButton component="span">
                                            Upload from computer
                                        </StyledUploadButton>
                                    </label>
                                </>
                                :
                                <>
                                    <SvgIcon className="download_icon"/>
                                    <Typography
                                        className="file_download_text">{file?.name || "dids_for_marriot.xlsx"}</Typography>
                                    <Typography
                                        className="file_download_span">{getFileSize(file?.size || 20000)}</Typography>
                                    <StyledLeaveButton 
                                        className={`${bulkAddDIDResponse.isLoading || bulkUpdateDIDResponse.isLoading ? "inactive" : ""}`}
                                        disabled={bulkAddDIDResponse.isLoading || bulkUpdateDIDResponse.isLoading} 
                                        onClick={handleFileRemove}>
                                        Remove
                                    </StyledLeaveButton>
                                </>
                        }
                    </Box>
                </Box>
                <Box className="container_file_size">
                    {fileSizeError &&
                    <Box className="file_error_block"><ErrorAlert type="invalid_file_size" setError={setFileSizeError}/></Box>}
                </Box>
                <Box className="file_box">
                    {action === "bulk_update" && errors && !fileSizeError ?
                    (Array.isArray(bulkUpdateDIDResponse.error.data?.error?.message) ? getErrors(bulkUpdateDIDResponse.error.data.error.message).map(error =>
                            <Box className={`${error.type.includes("warning") ? "file_warning_block" : "file_error_block"}`}>
                                <ErrorAlert
                                    key={error.type}
                                    type={error.type}
                                    location={error.location}
                                />
                            </Box>) :
                        <Box className="file_error_block">
                            <ErrorAlert
                                key={"other_error"}
                                type={"other_error"}
                                location={null}
                            />
                        </Box>) : null
                    }
                    {action === "bulk_import" && errors && !fileSizeError ?
                    (Array.isArray(bulkAddDIDResponse.error?.data?.error?.message) ? getErrors(bulkAddDIDResponse.error.data.error.message).map(error =>
                            <Box className={`${error.type.includes("warning") ? "file_warning_block" : "file_error_block"}`}>
                                <ErrorAlert
                                    key={error.type}
                                    type={error.type}
                                    location={error.location}
                                />
                            </Box>) :
                        <Box className="file_error_block">
                            <ErrorAlert
                                key={"other_error"}
                                type={"other_error"}
                                location={null}
                            />
                        </Box>) : null
                    }
                </Box>

                {(bulkAddDIDResponse.isLoading || bulkUpdateDIDResponse.isLoading) &&
                <Box className="download_block">
                    <SvgIcon className="progress_icon"/>
                    <Typography className="file_download_text_progress">
                        {`Data ${action === "bulk_import" ? "import" : "update"} is in progress. Please do not close the window until it's complete.`}
                    </Typography>
                </Box>}
            </StyledBulkActionPaper>
            <Box className="button_box">
                <LeavePageDialog/>
                <StyledNextButton onClick={handleSubmit} disabled={bulkAddDIDResponse.isLoading || bulkUpdateDIDResponse.isLoading}>
                    {buttonText} 
                </StyledNextButton>
            </Box>
        </Box>
    )
}

export default BulkImportUpdateDIDs;
