import React, {useState} from 'react'
import {Box, InputAdornment} from "@mui/material";
import {StyledFilterTextField, StyledInputLabel, StyledFormHelperText, StyledDatePicker} from '../styles/mui_styles';
import {AddNewDIDFields} from "../constants/constants";

function AddNewDIDInputField({required, name, value, handleChange, errorsStr, disabled, feature}) {
  // const value = valueStr;
  const errors =  errorsStr ? errorsStr.split(",") : null;
  
  return (
    <Box className="add_did_block test_field">
        <StyledInputLabel id={`${name}-label`}>  
            {
              feature === "filters" && (name === 'BSEnterpriseId' || name === 'BSGroupId') ? 
              `Broadsoft ${AddNewDIDFields[name].label}` :  
              AddNewDIDFields[name].label
            }
            {required && <span className="required_asterisk">*</span>}</StyledInputLabel>
        <StyledFilterTextField
            disabled={disabled}
            required={required}
            error={ errors && errors.length > 0}
            InputProps={{
              startAdornment: (name=== "number" && <InputAdornment position="start"><span className="did_code">+1</span></InputAdornment>),
            }}
            name="number"
            value={value}
            onChange={(e) => handleChange(name, e.target.value)}
            variant="outlined"
            placeholder="Type"
            size="small"
            fullWidth
        />
         {errors && errors.map(element => <StyledFormHelperText key={element}>{element}</StyledFormHelperText>)}
    </Box>
  )
}

export default React.memo(AddNewDIDInputField);
