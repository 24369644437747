import React, {useState } from 'react'
import { Box} from '@mui/material';
import {StyledMenuItem,StyledRedMenuItem, StyledPopover, StyledAddList} from "../styles/mui_styles";
import {MoreVert} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ReturnDIDDialog from './ReturnDIDDialog';

function DIDDetailsPopover({id}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const [openReturnDialog, setopenReturnDialog] = useState(false);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? 'simple-popover' : undefined;

    const handleViewDIDDetails= (action) => {
        if (action === "return") {
            setopenReturnDialog(true);
        } else {
            const path = action === "details" ? id : id + "/edit";
            navigate(path);
        }
        handleClose();
    }


  return (
    <Box className="more_vert_box">

        <MoreVert onClick={handleClick} className="more_vert_icon"/>
        <StyledPopover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <StyledAddList>

                <StyledMenuItem onClick={()=> handleViewDIDDetails("details")}>
                    View DID details
                </StyledMenuItem>
                <StyledMenuItem onClick={()=> handleViewDIDDetails("edit")}>
                    Edit DID details
                </StyledMenuItem>
                <StyledRedMenuItem onClick={()=> handleViewDIDDetails("return")}>
                    Return DID
                </StyledRedMenuItem>
            </StyledAddList>
        </StyledPopover>
        {openReturnDialog && <ReturnDIDDialog id={id} open={openReturnDialog} setOpen={setopenReturnDialog}/>}
    </Box>
  )
}

export default DIDDetailsPopover;
