import React, { useEffect } from 'react';
import { useExportDidsMutation } from '../services/requests';
import {
    StyledExportButton,
    StyledCancelButton,
    StyledDialog,
    StyledExportDialogTitle,
    StyledDialogContent,
    StyledDialogContentText,
    StyledDialogActions,
    StyledCircularProgress
} from '../styles/mui_styles';

function ExportDIDDialog({open, setOpen, ...rest}) {

    const [exportRequest, exportResponse] = useExportDidsMutation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleExport = ()=> {
        exportRequest({...rest});
    }

    useEffect(() => {
      if (exportResponse.data) {
          const anchor = document.createElement("a");
          anchor.href = exportResponse.data;
          document.body.appendChild(anchor);
          anchor.click();
          handleClose();
          return () => {
            document.body.removeChild(anchor);
          }
      }
    }, [exportResponse.data]) 

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
        >
            <StyledExportDialogTitle id="alert-dialog-title">
                Export DIDs
            </StyledExportDialogTitle>
            <StyledDialogContent>
                <StyledDialogContentText id="alert-dialog-description">
                    This will export only DIDs that are currently filtered in DIDs page. DIDs will be exported in
                    .xlsx format.
                </StyledDialogContentText>
            </StyledDialogContent>
            <StyledDialogActions>
                <StyledCancelButton onClick={handleClose} variant="outlined">Cancel</StyledCancelButton>
                <StyledExportButton variant="outlined" onClick={handleExport}>
                {
                    exportResponse.isLoading || exportResponse.isSuccess?
                    <StyledCircularProgress size="1.4rem" className="light-background"/> :
                    "Export"
                }
                </StyledExportButton>
            </StyledDialogActions>
        </StyledDialog>
    );
}

export default React.memo(ExportDIDDialog);