import React, {useState, useCallback, useEffect} from 'react'
import dayjs from 'dayjs';
import {createTheme} from "@material-ui/core/styles";
import {Box, Typography, SvgIcon, Tooltip, IconButton, Badge, Popover} from '@mui/material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {
    StyledIconButton,
    StyledClearButton,
    StyledInputLabel,
    StyledApplyButton,
    StyledDatePicker,
} from "../styles/mui_styles";
import {didInitialStateForFilters} from "../constants/constants";
import {useGetCustomersQuery, useGetPartnersQuery} from '../services/requests';
import {didDataProcessor} from '../services/didDataProcessor';
import AddNewDIDSelectField from './AddNewDIDSelectField';
import AddNewDIDInputField from './AddNewDIDInputField';
import {Clear} from '@mui/icons-material';


function DateIcon(props) {
    return (
        <SvgIcon {...props} className="calendar_icon"/>
    );
}

const singleSelectFields = ["country", "type", "portStatus"];
const badgeStyle = {
    "& .MuiBadge-badge": {
        backgroundColor: '#f05659',
    }
}
const popoverStyle = {
    "& .MuiPaper-root": {
        overflowY: 'hidden',
    }
}


function FilterPopover({setFilters, filters, setPage, filterData, setFilterData}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateError, setDateError] = useState("");

    const [customersToSkip, setCustomersToSkip] = useState(0);
    const [customerValue, setCustomerValue] = useState("");
    const [skipCustomersRequest, setSkipCustomersRequest] = useState(false)

    const {data: partnersData} = useGetPartnersQuery();
    const {data: customersData, isFetching, isLoading} = useGetCustomersQuery({
        limit: 20,
        skip: customersToSkip,
        value: customerValue
    }, {skip: skipCustomersRequest});
    const [customersCache, setCustomersCache] = useState([]);

    useEffect(() => {
        let notConcated = true;
        if (customersData && notConcated) {
            setCustomersCache(prev => {
                const newData = JSON.parse(JSON.stringify(prev));
                return newData.concat(customersData)
            })
        }
        if (customersData && !customersData.length) {
            setSkipCustomersRequest(true);
        }
        return () => {
            notConcated = false;
        }
    }, [customersData])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const cachedHandleChange = useCallback((name, value) => {
        setFilterData(prev => ({...prev, [name]: value}));
        if (name === "country") {
            setFilterData(prev => ({...prev, state: []}));
        }
        if (name === "dateFrom") {
            setDateError("");
            if (filterData.dateTo && dayjs(filterData.dateTo).diff(dayjs(value)) < 0) {
                setDateError("Invalid date range");
            }
        }
        if (name === "dateTo") {
            setDateError("");
            if (filterData.dateFrom && dayjs(value).diff(dayjs(filterData.dateFrom)) < 0) {
                setDateError("Invalid date range");
            }
        }
    }, [filterData.dateTo, filterData.dateFrom])

    const getProps = (name, fieldType) => {

        return {
            name: name,
            value: filterData[name],
            handleChange: cachedHandleChange,
            optionsStr: fieldType === "select" ? JSON.stringify(didDataProcessor.getOptions(filterData, name, partnersData, customersCache)) : "",
            disabled: didDataProcessor.checkIfDisabled(filterData, name, "filter"),
            multiple: singleSelectFields.includes(name) ? false : true,
            loading: isFetching || isLoading,
            setCustomersToSkip: setCustomersToSkip,
            setCustomerValue: setCustomerValue,
            setCustomersCache: setCustomersCache,
            customerValue: customerValue,
            customersToSkip: customersToSkip,
            setSkipCustomersRequest: setSkipCustomersRequest,
            skipCustomersRequest: skipCustomersRequest,
            feature: "filters"
        }
    }

    const handleApply = () => {
        if (dateError) return;
        let filtersToSend = {};
        for (const key in filterData) {
            const value = filterData[key];
            if (Array.isArray(value) && value.length > 0) {
                const keyToSend = key === "customer" || key === "partner" ? key + "ID" : key;
                filtersToSend[keyToSend] = value.map(item => item.id).join(",")
            }
            if ((key === "dateFrom" || key === "dateTo") && value) {
                filtersToSend[key] = dayjs(value).toISOString();
            }
            if (singleSelectFields.includes(key) && value.id) {
                filtersToSend[key] = value.id
            }
            if (typeof value === "string" && value.length > 0) {
                filtersToSend[key] = value;
            }
        }
        setFilters(Object.keys(filtersToSend).length > 0 ? filtersToSend : null);
        setPage(0);
        handleClose();
    }

    const handleClear = () => {
        setDateError("");
        setFilterData(didInitialStateForFilters);
        setFilters(null);
    }
    return (
        <Box>
            <Tooltip title="Filter" placement="top" arrow>
                <StyledIconButton
                    aria-describedby={id}
                    onClick={handleClick}
                    className="filter_button"
                >
                    {
                        filters ?
                            <Badge variant="dot" overlap="circular" sx={badgeStyle}>
                                <SvgIcon className="filter_icon"/>
                            </Badge> :
                            <SvgIcon className="filter_icon"/>
                    }
                </StyledIconButton>
            </Tooltip>
            <Popover
                sx={popoverStyle}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box className="filter_block">
                    <Typography className="filter_title">Filters</Typography>
                    <Box className="filter_form_control">
                        <Box className="status">
                            <AddNewDIDSelectField {...getProps("status", "select")} />
                        </Box>
                        <Box className="carrier">
                            <AddNewDIDSelectField {...getProps("carrier", "select")}/>
                        </Box>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledInputLabel className="date_created">Date Created</StyledInputLabel>
                            <Box className="data_picker_block test_field">
                                <Box className="data_picker_block_divider">
                                    <StyledDatePicker
                                        hiddenLabel
                                        variant="standard"
                                        sizeSmall
                                        placeholder="From"
                                        format="MM-DD-YYYY"
                                        slots={{
                                            openPickerIcon: DateIcon
                                        }}
                                        slotProps={{
                                            textField: {
                                                placeholder: 'From',
                                                InputProps: {
                                                    startAdornment: (
                                                        filterData.dateFrom &&
                                                        <IconButton className="date-clear-button" size="small"
                                                                    onClick={() => cachedHandleChange("dateFrom", null)}>
                                                            <Clear fontSize="small"
                                                                   sx={{width: "14px", height: "14px"}}/>
                                                        </IconButton>
                                                    ),

                                                },
                                            },
                                            inputAdornment: {
                                                position: "end",
                                                style: {order: 2, marginLeft: 0,}
                                            }
                                        }}
                                        value={filterData.dateFrom}
                                        onChange={(value) => cachedHandleChange("dateFrom", value)}
                                    />
                                    -
                                    <StyledDatePicker
                                        error={dateError}
                                        hiddenLabel
                                        variant="standard"
                                        sizeSmall
                                        placeholder="To"
                                        format="MM-DD-YYYY"
                                        slots={{
                                            openPickerIcon: DateIcon
                                        }}
                                        slotProps={{
                                            textField: {
                                                placeholder: 'To',
                                                InputProps: {
                                                    startAdornment: (
                                                        filterData.dateTo &&
                                                        <IconButton className="date-clear-button" size="small"
                                                                    onClick={() => cachedHandleChange("dateTo", null)}>
                                                            <Clear fontSize="small"
                                                                   sx={{width: "14px", height: "14px"}}/>
                                                        </IconButton>
                                                    ),
                                                },
                                            },
                                            inputAdornment: {
                                                position: "end",
                                                style: {order: 2, marginLeft: 0,}
                                            }
                                        }}
                                        value={filterData.dateTo}
                                        onChange={(value) => cachedHandleChange("dateTo", value)}
                                    />
                                </Box>
                                {dateError && <Typography className="date_error" align='right'>{dateError}</Typography>}
                            </Box>

                        </LocalizationProvider>
                        <Box className="country">
                            <AddNewDIDSelectField {...getProps("country", "select")}/>
                        </Box>
                        <Box className="state">
                            <AddNewDIDSelectField {...getProps("state", "select")}/>
                        </Box>
                        <Box className="city">
                            <AddNewDIDInputField {...getProps("city", "input")}/>
                        </Box>
                        <Box className="rateCenter">
                            <AddNewDIDInputField {...getProps("rateCenter", "input")}/>
                        </Box>

                        <Box className="partner">
                            <AddNewDIDSelectField {...getProps("partner", "select")}/>
                        </Box>
                        <Box className="customer">
                            <AddNewDIDSelectField {...getProps("customer", "select")}/>
                        </Box>
                        <Box className="type">
                            <AddNewDIDSelectField {...getProps("type", "select")}/>
                        </Box>
                        <Box className="portStatus">
                            <AddNewDIDSelectField {...getProps("portStatus", "select")}/>
                        </Box>
                        <Box>
                            <AddNewDIDSelectField {...getProps("services", "select")}/>
                        </Box>
                        <Box>
                            <AddNewDIDInputField {...getProps("BSEnterpriseId", "input")}/>
                        </Box>
                        <Box>
                            <AddNewDIDInputField {...getProps("BSGroupId", "input")}/>
                        </Box>

                    </Box>
                    <Box className="filter_box_button">
                        <StyledClearButton onClick={handleClear}>Clear</StyledClearButton>
                        <StyledApplyButton variant="contained" onClick={handleApply}>Apply</StyledApplyButton>
                    </Box>
                </Box>
            </Popover>
        </Box>
    )
}

export default FilterPopover;
