import React from "react";
import {Box, Typography, SvgIcon } from "@mui/material";
import { Outlet } from "react-router-dom";

function ExceptionsPage() {
    return (
        <Box>
          <Box className="did_header">
            <Box>
              <SvgIcon className="paper_header_icon"/>
            </Box>
            <Typography className="did_header-title">
              Exceptions
            </Typography>
          </Box>
          <Box className="exception_section_box">
            <Outlet />
          </Box>
        </Box>
      )
}

export default ExceptionsPage