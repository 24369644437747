import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { requests } from './services/requests';

export const store = configureStore({
  reducer: {
    [requests.reducerPath]: requests.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(requests.middleware)
});

setupListeners(store.dispatch);