import React, {useEffect} from 'react';
import {StyledLeaveButton,StyledCancelButton, StyledDialog, StyledDialogTitle, StyledDialogContent, StyledDialogContentText,StyledDialogActions, StyledCircularProgress} from '../styles/mui_styles';
import { useReturnDidMutation } from '../services/requests';
import { useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';

export default function ReturnDIDDialog({id, open, setOpen}) {
  const navigate = useNavigate();

  const [returnDIDRequest, returnDIDResponse] = useReturnDidMutation();

  useEffect(() => {
    navigate("/dids", {replace: true});
  }, [])

  const handleClose = () => {
    flushSync(() => {
      setOpen(false);
    });
  };

  const handleReturn= () => {
    returnDIDRequest(id);
  }

  useEffect(() => {
    if (returnDIDResponse.isSuccess) {
      handleClose();
      navigate("/dids", {replace: true, state: 'return_did_success'});
    }
  }, [returnDIDResponse.isSuccess])

  return (
      <StyledDialog
        open={open}
        onClose={handleClose}
      >
        <StyledDialogTitle id="alert-dialog-title">
          Return DID
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledDialogContentText id="alert-dialog-description">
            Are you sure you want to return the DID to the provider?
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledCancelButton onClick={handleClose} variant="outlined">Cancel</StyledCancelButton>
          <StyledLeaveButton onClick={handleReturn} variant="outlined">
          {
            returnDIDResponse.isLoading ?
            <StyledCircularProgress size="1.4rem" className="light-background"/> :
            "Return"
          }
          </StyledLeaveButton>
        </StyledDialogActions>
      </StyledDialog>
  );
}
