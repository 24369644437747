import React, {useState, useCallback} from 'react';
import {Box} from '@mui/material';
import {StyledMenuItem, StyledPopover, StyledIconButton, StyledAddList} from "../styles/mui_styles";
import {MoreVert} from '@mui/icons-material';
import {useNavigate} from "react-router-dom";
import ExportDIDDialog from "./ExportDIDDialog";


function BulkUpdatePopover(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [exportDialogOpen, setExportDialogOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleBulkUpdate = () => {
        navigate('bulk_update');
        handleClose();
    }


    const handleExport = () => {
        handleClose();
        setExportDialogOpen(true);
    };

    return (
        <Box className="bulk_update_popover">
            <MoreVert onClick={handleClick} className="more_vert_icon"/>
            <StyledPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <StyledAddList>
                    <StyledMenuItem onClick={handleBulkUpdate}>
                        Bulk update DIDs
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleExport}>
                        Export DIDs
                    </StyledMenuItem>
                </StyledAddList>
            </StyledPopover>
           {exportDialogOpen && <ExportDIDDialog open={exportDialogOpen} setOpen={setExportDialogOpen} {...props}/>}
        </Box>
    )
}

export default React.memo(BulkUpdatePopover);
