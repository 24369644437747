import React, {useState} from 'react';
import {Box, IconButton, Slide }from '@mui/material';
import {StyledWarningAlert}from "../styles/mui_styles";
import CloseIcon from '@mui/icons-material/Close';

export default function WarningAlert() {
  const [open, setOpen] = useState(true);

  return (
      open &&
      <Box className="alert_block warning">
      <Slide in={open} direction="left" timeout={800}>
        <StyledWarningAlert
        icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          The DID is currently held for a customer and cannot be edited. It is scheduled to be released at any time within the next hour.
        </StyledWarningAlert>
      </Slide>
    </Box>
  );
}
