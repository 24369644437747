import React, {useRef, useEffect, useState} from 'react'
import { StyledUploadButton, StyledLeaveButton} from "../styles/mui_styles";
import { Box, Typography, SvgIcon } from '@mui/material';


function FileUploadDrop({file, setFile, resetErrors, disableRemove}) {
    const dragArea = useRef(null);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        const elem = dragArea.current;
        if (elem) {
            ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
                dragArea.current.addEventListener(eventName, preventDefaults, false)
            })
            elem.addEventListener('drop', handleDrop, false)

        }

        function handleDrop(ev) {
            setDragging(false)
            ev.preventDefault();
            ev.stopPropagation();

            if (ev.dataTransfer.files) {
                const files = [...ev.dataTransfer.files];
                if (files.length > 1) return;
                const file = files[0];
                const splitFileName = file.name.split(".")
                const fileType = splitFileName[splitFileName.length - 1];
                if (!["xls", "xlsx"].includes(fileType)) {
                    return;
                }
                resetErrors();
                setFile(file);
            }
        }

        function preventDefaults(e) {
            setDragging(prev => {
              return prev === false && e.type === "dragover" ? true :
              e.type === "dragleave" ? false : prev;
            })
            e.preventDefault()
            e.stopPropagation()
        }

        return () => {
            if (elem) {
                ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
                    elem.removeEventListener(eventName, preventDefaults, false);
                    elem.removeEventListener('drop', handleDrop, false);
                })
            }
        }
    }, [dragArea])

    const handleFileUpload = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = [...event.target.files];
        const file = files[0];
        resetErrors();
        setFile(file);
      }

      const handleFileRemove = () => {
        setFile(null);
        resetErrors();
      }

      const getFileSize = (sizeInBytes) => {
        const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(5);
        return `${sizeInMB}MB`;
    }


  return (
    <Box ref={dragArea} className={`drag_drop_block ${dragging ? "dragging" : ""}`}>
    {
        !file
            ?
            <Box className="drag_block">
                <SvgIcon className="drag_drop_icon_exception"/>
                <Typography className="drag_drop_title">Drop your file here</Typography>
                <Typography className="drag_drop_title">or</Typography>
                <label htmlFor="upload-photo">
                    <input
                        className="upload_type"
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        accept=".xls,.xlsx"
                        onChange={(event) => handleFileUpload(event)}
                        onClick={(event) => event.target.value = null}
                        style={{visibility: "hidden"}}
                    />
                    <StyledUploadButton component="span">
                        Upload from computer
                    </StyledUploadButton>
                </label>
            </Box>
            :
            <Box className="drag_block">
                <SvgIcon className="download_icon_exception"/>
                <Typography
                    className="file_download_text">{file?.name || "dids_for_marriot.xlsx"}</Typography>
                <Typography
                    className="file_download_span">{getFileSize(file?.size || 20000)}</Typography>
                <StyledLeaveButton
                    className={disableRemove ? "inactive" : ""}
                    disabled={disableRemove}
                    onClick={handleFileRemove}>
                    Remove
                </StyledLeaveButton>
            </Box>
        }
    </Box>
  )
}

export default FileUploadDrop
