import React from 'react';
import {Box, TextField, Typography} from '@mui/material';
import {StyledInputLabel,StyledAutocomplete} from "../../styles/mui_styles";
import {didDataProcessor} from '../../services/didDataProcessor';

function SyncDataStepOne({carrier, setCarrier}) {
    return (
        <Box className="step_one_container">
            <Typography className="step_one_title">
                Choose the provider you want to compare with DID Management System data to
                detect any inconsistencies between the two systems.
            </Typography>
            <Box className="step_one_input">
                <StyledInputLabel>Provider</StyledInputLabel>
                <StyledAutocomplete
                    disablePortal
                    value={carrier}
                    size="small"
                    onChange={(event, newValue, reason) => {
                        const newVal = reason === "clear" ? {id: "", label: ""} : newValue;
                        setCarrier(newVal);
                    }}
                    ListboxProps={{
                        sx: { fontSize: 14 },
                    }}
                    options={didDataProcessor.getOptions("_", "carrier")}
                    isOptionEqualToValue={(option, value) => {
                        return option?.id === value?.id;
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Choose"/>}
                />
            </Box>
        </Box>
    )
}

export default SyncDataStepOne;
