import React, {useState, useRef} from 'react';
import {StyledAdvancedSearchButton, StyledAdvancedSearchDialog,StyledAdvancedSearchDialogTitle,StyledAdvancedSearchDialogContent,StyledAdvancedSearchDialogContentText, StyledAdvancedSearchDialogActions, StyledAdvancedDialogTypography, StyledAdvancedErrorDialogTypography, StyledDialogBackButton, StyledDialogNextButton } from '../styles/mui_styles';

export default function AdvancedSearch({setSearch, search, bulkSearchNumbers, setBulkSearchNumbers}) {
    const [open, setOpen] = useState(false);
    const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState([]);

    const textareaRef = useRef();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason !== "backdropClick") {
          setOpen(false);
          setInvalidPhoneNumbers([]);
          if (!search?.length) {
            setBulkSearchNumbers([]);
          }
      }
    };


    const validateData = (value) => {
        const data = [];
        const invalidNumbers = [];
        let currNumber = "";
        for (let i = 0; i < value.length; i++) {
            const char = value[i];
            if (char !== " " && char !== "," && char !== "\n" && char !== "\t") {
                    currNumber = `${currNumber}${char}`;
            } else {
                if (currNumber) {
                    const valid = validateNumber(currNumber);
                    if (!valid) {
                        invalidNumbers.push(currNumber)
                    }
                    data.push({number: currNumber, valid});
                    currNumber = "";
                }
            }
        }
        if (currNumber.length) {
            const valid = validateNumber(currNumber);
            if (!valid) {
                invalidNumbers.push(currNumber)
            }
            data.push({number: currNumber, valid});
        }
        return {data, invalidNumbers};
    }

    const validateNumber = (phoneNumber)=> {
        const isValid = (phoneNumber.length === 10 && /^\d{10}$/.test(phoneNumber)) ||
            (phoneNumber.length === 11 && phoneNumber[0] === '1' && /^\d{11}$/.test(phoneNumber));
        return isValid;
    }


    const handleSearch = () => {
        const {data, invalidNumbers} = validateData(textareaRef.current.value);
        setInvalidPhoneNumbers(invalidNumbers);
        if (invalidNumbers.length) {
           return;
        } else {
            setSearch(data.map(item => item.number))
            setOpen(false);
        }
    }

  return (
    <>
      <StyledAdvancedSearchButton variant="outlined" onClick={handleClickOpen} className={`${search?.length ? "advancedSearchApplied": ""}`}>
        Advanced Search
      </StyledAdvancedSearchButton>
      <StyledAdvancedSearchDialog
        open={open}
        onClose={handleClose}
      >
        <StyledAdvancedSearchDialogTitle>Advanced Search</StyledAdvancedSearchDialogTitle>
        <StyledAdvancedSearchDialogContent>
          <StyledAdvancedSearchDialogContentText>
            Bulk Search for DIDs
          </StyledAdvancedSearchDialogContentText>
            <textarea
                className="search_textarea"
                value={bulkSearchNumbers}
                onChange={(e)=> setBulkSearchNumbers(e.target.value)}
                ref={textareaRef}
                placeholder='Enter DIDs'
                id="didsTextarea"
            />
            {!invalidPhoneNumbers.length && <StyledAdvancedDialogTypography>Separate DIDs with commas or spaces (e.g. 3035552314, 9195554832 8046657213), or list them on separate lines.</StyledAdvancedDialogTypography>}
            {
                invalidPhoneNumbers.length ?
                <StyledAdvancedErrorDialogTypography style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                    The following DIDs are invalid: {" "}
                    {invalidPhoneNumbers.join(", ")}
                </StyledAdvancedErrorDialogTypography> : null
            }
        </StyledAdvancedSearchDialogContent>
        <StyledAdvancedSearchDialogActions>
          <StyledDialogBackButton
            onClick={handleClose} 
            variant='outlined'
          >
            Close
          </StyledDialogBackButton>
          <StyledDialogNextButton
            onClick={handleSearch}
            variant='outlined'
            disabled={!bulkSearchNumbers.length}
          >
            Search
            </StyledDialogNextButton>
        </StyledAdvancedSearchDialogActions>
      </StyledAdvancedSearchDialog>
    </>
  );
}