import { useState, useEffect } from "react";
import {Box, Typography, SvgIcon } from "@mui/material";
import { Outlet, useLocation} from "react-router-dom";

function DIDsPage() {
    const [didsPageHeader, setDidsPageHeader] = useState("All DIDs")
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === "/dids") {
          setDidsPageHeader("All DIDs");
      } else {
        const pathsArray = location.pathname.split('/');
        if (pathsArray[pathsArray.length - 1] === "new_did") {
          setDidsPageHeader("New DID");
        } else if (pathsArray[pathsArray.length - 1] === "edit") {
          setDidsPageHeader("Edit DID");
        } else if (pathsArray[pathsArray.length - 1] === "bulk_import") {
          setDidsPageHeader("Import DIDs");
        } else if (pathsArray[pathsArray.length - 1] === "bulk_update") {
          setDidsPageHeader("Update DIDs");
        }else {
          setDidsPageHeader("DID Details");
        }
      }
    }, [location.pathname])

    return (
      <Box>
        <Box className="did_header">
            <Box>
              <SvgIcon className="did_header-icon"/>
            </Box>
            <Typography className="did_header-title">
              {didsPageHeader}
            </Typography>
        </Box>
         <Box className="add_did_section">
          <Outlet />
         </Box>
      </Box>
    )
}

export default DIDsPage;
