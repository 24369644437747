import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../../config/config";
import {Box, Typography} from '@mui/material';
import {StyledContainer, StyledBorderedButton, StyledLink} from "../../styles/mui_styles";



export default function Login() {
    const {instance} = useMsal();


    function handleLogin() {
        instance.loginRedirect(loginRequest);
    }

    return (
        <div className="paper">
            <StyledContainer elevation={3} maxWidth="sm">
                <Box className="paper-header">
                    <Box className="bluip-logo"/>
                </Box>
                <Box className="paper_content">
                    <Typography variant="h6" className="paper_title">
                        Welcome to BluIP!
                    </Typography>
                    <Typography className="paper_paragraph">
                        Sign in with your account credentials to continue.
                    </Typography>
                    <StyledBorderedButton variant="contained" onClick={() => handleLogin()}>
                        Sign in with Active Directory
                    </StyledBorderedButton>
                </Box>
                <Box className="paper_footer">
                    <Typography variant="caption" className="footer_paragraph">
                        By signing in you agree to BluIP's <StyledLink target="_blank"
                        href="https://storage.googleapis.com/bluip-storage/legal/BIP.TOS.vers.05.26.22.cln.2%5B71%5D.pdf">Terms of
                        Use</StyledLink> and <StyledLink target="_blank"
                        href="https://storage.googleapis.com/bluip-storage/legal/BIP.Privacy%20Policy.vers.05.26.22.cln.pdf ">Privacy
                        Policy.</StyledLink>
                    </Typography>
                </Box>
            </StyledContainer>
        </div>
    );
}
