import * as React from 'react';
import {StyledBackButton,StyledLeaveButton,StyledCancelButton, StyledDialog, StyledDialogTitle, StyledDialogContent, StyledDialogContentText,StyledDialogActions} from '../styles/mui_styles';
import { useNavigate } from 'react-router-dom';

export default function LeavePageDialog() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLeave = () => {
      navigate('/dids', {replace: true});
  }

  return (
    <>
      <StyledBackButton variant="outlined" onClick={handleClickOpen}>
        Cancel
      </StyledBackButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
      >
        <StyledDialogTitle id="alert-dialog-title">
          Discard Changes
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledDialogContentText id="alert-dialog-description">
            You have unsaved changes. Are you sure you want to leave the form?
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledCancelButton onClick={handleClose} variant="outlined">Cancel</StyledCancelButton>
          <StyledLeaveButton onClick={handleLeave} variant="outlined">
           Leave
          </StyledLeaveButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
}
