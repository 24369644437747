import React, {useState, useCallback, useEffect, useRef} from 'react'
import {Box, Grid, Typography} from "@mui/material";
import {StyledLinearProgress, StyledNextButton, StyledSmallCircularProgress} from "../styles/mui_styles";
import AddNewDIDSelectField from './AddNewDIDSelectField';
import AddNewDIDInputField from './AddNewDIDInputField';
import DIDCheckbox from './DIDCheckbox';
import ConfirmManualEditDialog from './ConfirmManualEditDialog';
import {useAddDidMutation, useGetCustomersQuery, useGetPartnersQuery, useUpdateDidMutation, useGetBSDataMutation} from '../services/requests';
import {didInitialState, didErrorsInitialState, didExternalDataErrorsInitialState} from "../constants/constants";
import {useNavigate, useLocation} from 'react-router-dom';
import LeavePageDialog from './LeavePageDialog';
import {useGetDidQuery, useGetDidExternalDataQuery} from '../services/requests';
import {didDataProcessor} from '../services/didDataProcessor';
import useGetCustomers from './custom_hooks/useGetCustomers';
import WarningAlert from './WarningAlert';

function AddNewDID() {
    const location = useLocation();
    const pathsArray = location.pathname.split('/');
    const id = pathsArray[2];
    const action = pathsArray[3];
    const timestampRef = useRef(Date.now()).current;
    const {data, isFetching, isLoading} = useGetDidQuery({id: id, includeAuditLogs: action !== "edit", sessionId: timestampRef}, {skip: action !== "edit"});
    const [isTouched, setIsTouched] = useState(false);

    const [didData, setDidData] = useState(didInitialState);
    const [errors, setErrors] = useState(didErrorsInitialState);
    const {data: didExternalData} = useGetDidExternalDataQuery({
        npa: didData.number.slice(0, 3),
        nxx: didData.number.slice(3, 6)
    }, {skip: didData.number.length < 6});
    const [didExternalDataErrors, setdidExternalDataErrors] = useState(didExternalDataErrorsInitialState);
    const [didExternalDataConfirmationDialog, setDidExternalDataConfirmationDialog] = useState(false);
    const [npaNxxCache, setNpaNxxCache] = useState(new Set());

    const {data: partnersData, isFetching: isFetchingPartners, isLoading: isLoadingPartners} = useGetPartnersQuery();
    const timestampRefCustomers = useRef(Date.now())
    const {
        customersToSkip,
        setCustomersToSkip,
        customerValue,
        setCustomerValue,
        customersCache,
        setCustomersCache,
        isFetchingCustomers, 
        isLoadingCustomers,
        skipCustomersRequest,
        setSkipCustomersRequest
    } = useGetCustomers({partnerId: didData.partner.id, sessionId: timestampRefCustomers.current})
    const [BSDataRequest, BSDataResponse] = useGetBSDataMutation();

    const [addDIDRequest, addDIDResponse] = useAddDidMutation();
    const [updateDIDRequest, updateDIDResponse] = useUpdateDidMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            const dbData = didDataProcessor.getDidData(data);
            setDidData(dbData);
        }
    }, [data])

    useEffect(() => {
        if (BSDataResponse?.data) {
            const BSData = BSDataResponse.data;
            setDidData(prev => {
                const newData = JSON.parse(JSON.stringify(prev));
                newData.BSEnterpriseName = BSData.serviceProviderName || "";
                newData.BSEnterpriseId = BSData.serviceProviderId || "";
                newData.BSGroupName = BSData.groupName || "";
                newData.BSGroupId = BSData.groupId || "";
                return newData;
            })
        }
    }, [BSDataResponse?.data])


    useEffect(() => {
        if (action !== "edit" && didExternalData && didExternalData.found) {
            setDidData((prev) => {
                const newData = JSON.parse(JSON.stringify(prev));
                newData.rateCenter = didExternalData.rateCenter;
                newData.country = didExternalData.country ? {
                    id: didExternalData.country,
                    label: didExternalData.country
                } : didInitialState.country;
                newData.state = {id: didExternalData.state, label: didExternalData.state};
                newData.city = didExternalData.city;
                return newData;
            });
            setErrors(prev => {
                const newErrors = JSON.parse(JSON.stringify(prev));
                ["rateCenter", "country", "state", "city"].forEach(item => {
                    if (prev[item].length) {
                        newErrors[item] = [];
                    }
                });
                return newErrors;
            });
            const npaNxx = `${didExternalData.npa}${didExternalData.nxx}`
            if (npaNxx) {
                setNpaNxxCache(prev => prev.add(npaNxx));
            }
        }
    }, [didExternalData])

    const handleChange = (name, value) => {
        setIsTouched(prev => prev === false ? true : prev);
        const newValue = name === "number" ? value.slice(0, 10).replace(/[^0-9]+/g, '') : value;

        let prevCopy;
        setDidData(prev => {
            prevCopy = JSON.parse(JSON.stringify(prev));
            if (name === "country") {
                prevCopy.state = {id: "", label: ""};
            }
            if (name === "status" && newValue.id === "Available") {
                prevCopy.partner = {id: "", label: ""};
                prevCopy.customer = {id: "", label: ""};
                prevCopy.fax = false;
            }
            if (newValue === false &&
                ((name === "sms" && prevCopy.mms === false) || (name === "mms" && prevCopy.sms === false))) {
                prevCopy.smsMmsProvider = {id: "", label: ""};
            }
            if (action !== "edit" && name === "number") {
                if (newValue.slice(0, 6) !== prev.number.slice(0, 6)) {
                    if (npaNxxCache.has(newValue.slice(0, 6))) {
                        prevCopy.rateCenter = didExternalData.rateCenter;
                        prevCopy.country = didExternalData.country ? {
                            id: didExternalData.country,
                            label: didExternalData.country
                        } : didInitialState.country;
                        prevCopy.state = {id: didExternalData.state, label: didExternalData.state};
                        prevCopy.city = didExternalData.city !== "not_found" ? didExternalData.city : "";
                    } else {
                        prevCopy.rateCenter = didInitialState.rateCenter;
                        prevCopy.country = {...didInitialState.country};
                        prevCopy.state = {...didInitialState.state};
                        prevCopy.city = didInitialState.city;
                    }
                }
            }
            if (name === "partner") {
                timestampRefCustomers.current = Date.now();
                if (newValue.id !== prevCopy.partner.id) {
                    prevCopy.customer = {...didInitialState.customer}
                }
            }
            prevCopy[name] = newValue;
            return prevCopy;
        })
        if (name === "partner") {
            setCustomerValue("");
            setCustomersToSkip(0);
            setCustomersCache([]);
        }
        setErrors(prev => {
            const newErrors = JSON.parse(JSON.stringify(prev));
            if (prev[name].length) {
                newErrors[name] = [];
            }
            if (["type", "status", "sms", "mms", "fax"].includes(name)) {
                for (const key in newErrors) {
                    const required = didDataProcessor.checkIfRequired(prevCopy, key);
                    if (!required) {
                        newErrors[key] = [];
                    }
                }
            }
            return newErrors;
        });

        if (name === "number") {
            if (value.length === 10) {
                BSDataRequest({number : value});
            } else {
                setDidData(prev => {
                    const newData = JSON.parse(JSON.stringify(prev));
                    newData.BSEnterpriseName = "";
                    newData.BSEnterpriseId = "";
                    newData.BSGroupName = "";
                    newData.BSGroupId = "";
                    return newData;
                })
            }
        }
    }

    const cachedHandleChange = useCallback(handleChange, [action, didExternalData, npaNxxCache]);

    const getProps = (name, fieldType) => {
        return {
            name: name,
            value: didData[name],
            handleChange: cachedHandleChange,
            optionsStr: fieldType === "select" ? JSON.stringify(didDataProcessor.getOptions(didData, name, partnersData, customersCache)) : "",
            required: didDataProcessor.checkIfRequired(didData, name),
            errorsStr: errors[name].join(),
            disabled: didDataProcessor.checkIfDisabled(didData, name, action),
            loading: isLoadingCustomers || isFetchingCustomers || isLoadingPartners || isFetchingPartners,
            setCustomersToSkip: setCustomersToSkip,
            setCustomerValue: setCustomerValue,
            setCustomersCache: setCustomersCache,
            customerValue: customerValue,
            customersToSkip: customersToSkip,
            setSkipCustomersRequest: setSkipCustomersRequest,
            skipCustomersRequest: skipCustomersRequest,
        }
    }

    const handleSubmitDID = (action, confirmed = false) => {
        let valid = true;
        for (const key in didData) {
            const isEmpty = !didData[key] || (typeof didData[key] === "object" && !didData[key].id)
            if (didDataProcessor.checkIfRequired(didData, key) && isEmpty) {
                setErrors(prev => {
                    const newErrors = JSON.parse(JSON.stringify(prev));
                    !newErrors[key].includes("Field is required") && newErrors[key].push("Field is required");
                    return newErrors;
                });
                valid = false;
            }
        }
        if (didData.number.length < 10) {
            setErrors(prev => {
                const newErrors = JSON.parse(JSON.stringify(prev));
                newErrors.number.length === 0 && newErrors.number.push("DID has to be 10 digits");
                return newErrors;
            });
            valid = false;
        }
        if (valid === false) return;
        if (!confirmed) {
            const resp = didDataProcessor.validateManualChanges(data, didData, didExternalData, didExternalDataErrors, action);
            if (!resp.valid) {
                setdidExternalDataErrors(resp.errors);
                setDidExternalDataConfirmationDialog(true);
                return;
            }
        }

        const dataToSend = didDataProcessor.prepareRequestBody(didData, action);
        if (action === "edit") {
            updateDIDRequest({dataToSend, id});
        } else {
            addDIDRequest(dataToSend);
        }
    }

    useEffect(() => {
        if (addDIDResponse.error) {
            const err = addDIDResponse.error;
            if (err.data.error.message.message === "DID already exists") {
                setErrors(prev => {
                    const newErrors = JSON.parse(JSON.stringify(prev));
                    !newErrors.number.includes("DID already exists") && newErrors.number.push("DID already exists");
                    return newErrors;
                });
            }
        }
    }, [addDIDResponse.error])


    useEffect(() => {
        if (addDIDResponse.isSuccess) {
            navigate("/dids", {replace: true, state: 'new_did_success'});
        }
    }, [addDIDResponse.isSuccess])

    useEffect(() => {
        if (updateDIDResponse.isSuccess) {
            navigate("/dids", {replace: true, state: 'edit_did_success'});
        }
    }, [updateDIDResponse.isSuccess])

    return (
        <Box className="grid_block"> 
            <Box className="grid_container" container>
            {action === "edit" && data?.blocked && <WarningAlert />}
            <StyledLinearProgress 
                style={{visibility: (isFetching || isLoading) ? "visible" : "hidden"}} 
                variant="indeterminate"
            />
            <Box className="info_container" >
                <Box>
                    <Typography className="grid_container_title">GENERAL INFORMATION</Typography>
                    <Grid className="grid_general_information_container" item>
                        <AddNewDIDInputField {...getProps("number", "input")}/>
                        <AddNewDIDSelectField {...getProps("status", "select")}/>
                        <AddNewDIDInputField {...getProps("rateCenter", "input")}/>
                        <AddNewDIDSelectField {...getProps("country", "select")}/>
                        <AddNewDIDSelectField {...getProps("state", "select")}/>
                        <AddNewDIDInputField {...getProps("city", "input")}/>
                        <AddNewDIDSelectField {...getProps("carrier", "select")}/>
                        <AddNewDIDSelectField {...getProps("type", "select")}/>
                        <AddNewDIDSelectField {...getProps("portStatus", "select")}/>
                        <AddNewDIDSelectField {...getProps("partner", "select")}/>
                        <AddNewDIDSelectField {...getProps("customer", "select")}/>
                    </Grid>
                </Box>
                <Box>
                    <Typography className="grid_container_title">SERVICES</Typography>
                    <Grid className="grid_services_container" item>
                        <Box className="grid_container_checkbox">

                        <DIDCheckbox {...getProps("fax", "checkbox")}/>
                        <DIDCheckbox {...getProps("sms", "checkbox")}/>
                        <DIDCheckbox {...getProps("mms", "checkbox")}/>

                        </Box>
                    </Grid>
                    <Grid className="grid_services_container" item>
                        <AddNewDIDSelectField {...getProps("smsMmsProvider", "select")}/>
                    </Grid>
                </Box>
                <Box>
                    <Typography className="grid_container_title">BROADSOFT INFORMATION</Typography>
                    <Grid className="grid_broadsoft_container" item>
                        <Box>
                            <AddNewDIDInputField {...getProps("BSEnterpriseName", "input")}/>
                            <AddNewDIDInputField {...getProps("BSEnterpriseId", "input")}/>
                        </Box>
                        <Box>
                            <AddNewDIDInputField {...getProps("BSGroupName", "input")}/>
                            <AddNewDIDInputField {...getProps("BSGroupId", "input")}/>
                        </Box>
                    </Grid>
                </Box>
            </Box>
            </Box>
            <Box className="button_box">
                <LeavePageDialog/>
                <StyledNextButton
                    variant="contained"
                    onClick={() => handleSubmitDID(action === "edit" ? "edit" : "add", false)}
                    disabled={!isTouched || addDIDResponse.isLoading || updateDIDResponse.isLoading || (action === "edit" && data?.blocked)}
                >
                    {
                        addDIDResponse.isLoading || updateDIDResponse.isLoading ?
                            <Box><StyledSmallCircularProgress size="1rem"/></Box> :
                            action === "edit" ?
                                "Update" :
                                "Add DID"
                    }
                </StyledNextButton>
                {didExternalDataConfirmationDialog &&
                    <ConfirmManualEditDialog
                        data={didExternalDataErrors}
                        setData={setdidExternalDataErrors}
                        setDidExternalDataConfirmationDialog={setDidExternalDataConfirmationDialog}
                        action={action}
                        handleSubmitDID={handleSubmitDID}
                    />}
            </Box>
        </Box>
    )

}

export default AddNewDID;
