import {styled, Typography} from "@mui/material";
import {
    Button,
    Container,
    Link,
    Tab,
    Drawer as MuiDrawer,
    List,
    ListItemButton,
    MenuItem,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TextField,
    InputLabel,
    CircularProgress,
    TablePagination,
    Paper,
    Popover,
    Autocomplete,
    FormControl,
    FormControlLabel,
    Select,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
    Grid,
    Stepper,
    Step,
    StepLabel,
    LinearProgress,
    SvgIcon,
} from "@mui/material";
import StepConnector, {stepConnectorClasses} from "@mui/material/StepConnector";
import {DatePicker} from "@mui/x-date-pickers-pro";
import {Sync} from '@mui/icons-material';

export let StyledContainer;
export let StyledBorderedButton;
export let StyledLink;
export let StyledTab;
export let StyledDrawer;
export let DrawerHeader;
export let StyledLogoutList;
export let StyledMenuItem;
export let StyledIconButton;
export let StyledPaginationIconButton;
export let StyledTextField;
export let StyledFilterTextField;
export let StyledButton;
export let StyledUploadButton;
export let StyledCircularProgress;
export let StyledSmallCircularProgress;
export let StyledTableContainer;
export let StyledHistoryTableContainer;
export let StyledTable;
export let StyledTableHead;
export let StyledTableRow;
export let StyledTableCell;
export let StyledTablePagination;
export let StyledProfileIconButton;
export let StyledList;
export let StyledAddList;
export let StyledPaper;
export let StyledHistoryPaper;
export let StyledTablePaper;
export let StyledBulkActionPaper;
export let StyledPopover;
export let StyledFilterPopover;
export let StyledBackButton;
export let StyledDialogBackButton;
export let StyledCancelButton;
export let StyledEditButton;
export let StyledNextButton;
export let StyledDialogNextButton;
export let StyledAdvancedSearchButton;
export let StyledAdvancedSearchClearButton;
export let StyledConfirmButton;
export let StyledDownloadButton;
export let StyledClearButton;
export let StyledApplyButton;
export let StyledLeaveButton;
export let StyledExportButton;
export let StyledAutocomplete;
export let StyledFilterAutocomplete;
export let StyledInputLabel;
export let StyledFormControl;
export let StyledFormControlLabel;
export let StyledSelect;
export let StyledFormHelperText;
export let StyledDialog;
export let StyledAdvancedSearchDialog;
export let StyledAdvancedSearchDialogTitle;
export let StyledAdvancedSearchDialogContent;
export let StyledAdvancedSearchDialogContentText;
export let StyledAdvancedSearchDialogActions;
export let StyledAdvancedDialogTypography;
export let StyledAdvancedErrorDialogTypography;
export let StyledFoundTypography;
export let StyledNotFoundTypography;
export let StyledNumberTypography;
export let StyledConfirmDialog;
export let StyledDialogTitle;
export let StyledExportDialogTitle;
export let StyledDialogContent;
export let StyledConfirmDialogContent;
export let StyledDialogContentText;
export let StyledConfirmDialogContentText;
export let StyledDialogActions;
export let StyledConfirmDialogActions;
export let StyledAlert;
export let StyledErrorAlert;
export let StyledWarningAlert;
export let StyledRedMenuItem;
export let StyledDatePicker;
export let StyledListItemButton;
export let StyledGrid;
// export let StyledEditGrid;
export let StyledSyncIconButton;
export let StyledSyncIcon;
export let StyledSortSvgIcon;
export let StyledBroadsoftGrid;
export let StyledStepper;
export let StyledStep;
export let StyledStepLabel;
export let QontoConnector;
export let QontoStepIconRoot;
export let StyledLinearProgress;


StyledContainer = styled(Container)((theme) => ({
    "&.MuiContainer-root": {
        minWidth: 500,
        height: 450,
        padding: "30px 20px 15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0 1px 4px 0 rgb(0 0 0 / 50%)",
    },
}));

StyledStepper = styled(Stepper)(({theme}) => ({
    "&.MuiStepper-root": {
        width: "100%",
        marginTop: "3rem"
    },
    "&.MuiStepConnector-line": {
        borderColor: '#80a1fd',
    },
}));

StyledStep = styled(Step)(({theme}) => ({
    "&.MuiStepConnector-line": {
        borderColor: '#80a1fd',
    },
}));
StyledStepLabel = styled(StepLabel)((theme) => ({
    "&.MuiStepLabel-label ": {
        "&:active": {
            color: '#3f4244',
        }
    },
}));

QontoConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#80a1fd',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#80a1fd',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

StyledGrid = styled(Grid)((theme) => ({
    "&.MuiGrid-root": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(230px, 1fr))",
        gridGap: "40px 0",
        marginBottom: "20px",
        "@media (max-width: 1189px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        "@media (max-width: 600px)": {
            gridTemplateColumns: "repeat(2, 50%)",
        },
    },
}));
// StyledEditGrid = styled(Grid)((theme) => ({
//     "&.MuiGrid-root": {
//         display: "grid",
//         gridTemplateColumns: "repeat(auto-fit, minmax(520px, 1fr))",
//         gridGap: "0 40px",
//         marginBottom: "20px",
//         "@media (max-width: 1189px)": {
//             gridTemplateColumns: "repeat(2, 1fr)",
//         },
//         "@media (max-width: 600px)": {
//             gridTemplateColumns: "repeat(2, 50%)",
//         },
//     },
// }));
StyledBroadsoftGrid = styled(Grid)((theme) => ({
    "&.MuiGrid-root": {
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(230px, 1fr))",
        gridGap: "40px 0",
        "@media (max-width: 1189px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        "@media (max-width: 600px)": {
            gridTemplateColumns: "repeat(2, 50%)",
        },
    },
}));

StyledBorderedButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        color: "#fff",
        backgroundColor: "#80a1fd",
        borderRadius: "4px",
        textTransform: "initial",
        padding: "6px 20px",
        boxShadow: "0 0 0",
        "&:hover": {
            boxShadow: "0px 0px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        },
        "@media (max-width: 600px)": {
            fontSize: "11px"
        },
    }
}));
StyledUploadButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        color: "#80a1fd",
        textTransform: "initial",
        textDecoration: "underline",
        "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "0 0 0 0",
        },
        "&:active": {
            borderStyle: "outset"
        }
    }
}));
StyledListItemButton = styled(ListItemButton)((theme) => ({
    "&.MuiListItemButton-root": {
        padding: "16px 0 16px 32px",
        "@media (max-width: 600px)": {
            padding: "8px 0 8px 20px",
        }
    }
}));
StyledLink = styled(Link)((theme) => ({
    "&.MuiLink-root": {
        color: "#6f7780",
        textDecorationColor: "#6f7780",
        "&:hover": {
            color: "#80a1fd",
            textDecorationColor: "#80a1fd",
        },
        "&.data_loading": {
            color: "#C8C8C8"
        }
    }
}));

StyledTab = styled(Tab)((theme) => ({
    "&.MuiTab-root": {
        padding: "0 32px",
        "&.Mui-selected": {
            backgroundColor: "#f2f5ff",
            color: " #252423",
            padding: "0 32px",
        },
    },
}))
;

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: '#80a1fd',
    height: '100%',
    border: 'none',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
        width: `calc(${theme.spacing(8)} + 100px)`,
    },
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(11)} + 2px)`,
    backgroundColor: '#80a1fd',
    height: '100%',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
        width: `calc(${theme.spacing(6)} + 20px)`,
        backgroundColor: '#80a1fd',
        height: '100%',
        border: 'none',
    },
});

DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: "0 0 0 32px",
    "@media (max-width: 600px)": {
        padding: "0 0 0 20px",
    }
}));

StyledDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

StyledLogoutList = styled(List)((theme) => ({
    "&.MuiList-root": {
        padding: "0",
        width: "140px",
        border: '1px solid #caced1',
        borderRadius: '4px',
    }
}));
StyledMenuItem = styled(MenuItem)((theme) => ({
    "&.MuiMenuItem-root": {
        fontSize: "13px",
        color: "#3f4244",
        padding: "8px 16px",
        "&:hover": {
            backgroundColor: "rgba(108, 146, 251, 0.05)",
        },
        "@media (max-width: 600px)": {
            fontSize: "12px",
            minHeight: "30px"
        }
    },
}));
StyledRedMenuItem = styled(MenuItem)((theme) => ({
    "&.MuiMenuItem-root": {
        fontSize: "13px",
        color: "#d32f2f",
        padding: "8px 16px",
        "&:hover": {
            backgroundColor: "rgba(108, 146, 251, 0.05)",
        },
        "@media (max-width: 600px)": {
            fontSize: "12px",
            minHeight: "30px"
        }
    },
}));

StyledIconButton = styled(IconButton)`
  & {
    padding: 8px 0;
  }

  &:hover {
    background-color: transparent;
  }

  && .MuiTouchRipple-child {
    width: 0;
    height: 0;
  }
`;

StyledPaper = styled(Paper)((theme) => ({
    "&.MuiPaper-root": {
        height: "calc(100% - 50px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-around",
        boxShadow: '0 0 0 0(0)',
        borderRadius: '2px',
        margin: '8px',
        padding: '20px 16px',
        overflowY: "auto",
        "@media (min-width: 1800px)": {
            height: "calc(100% - 60px)",
        },
    }
}));

StyledBulkActionPaper = styled(Paper)((theme) => ({
    "&.MuiPaper-root": {
        display: "flex",
        flexDirection: "column",
        boxShadow: '0 0 0 0(0)',
        borderRadius: '2px',
        margin: '8px',
        padding: '8px 16px',
        "@media (min-width: 1800px)": {
            height: "70%",
        },
        "&.bulk_update": {
            height: "90%",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
        },
        "&.bulk_import": {
            height: "90%",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
        }
    }
}));
StyledTablePaper = styled(Paper)((theme) => ({
    "&.MuiPaper-root": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: '0 0 0 0(0)',
        borderRadius: '2px',
        margin: '8px',
        padding: '8px 16px',
    }
}));
StyledHistoryPaper = styled(Paper)((theme) => ({
    "&.MuiPaper-root": {
        height: "calc(100% - 50px)",
        display: "flex",
        flexDirection: 'column',
        boxShadow: '0 0 0 0(0)',
        borderRadius: '2px',
        margin: '8px',
        padding: '8px 16px',
        "@media (min-width: 1800px)": {
            maxHeight: "calc(100% - 60px)",
        },
    }
}));
StyledPopover = styled(Popover)((theme) => ({
    "&.MuiPopover-paper": {
        margin: '4px 8px',
        padding: '10px 20px',
        width: 'calc(100% - 17px)',
        height: '90%',
        overflowy: 'scroll',
        overflowx: 'hidden',
        borderRadius: '4px',
        boxShadow: '3px 3px 5px 0 rgba(132, 138, 145, 0.4)',
        border: 'solid 1px #caced1',
    }
}));
StyledFilterPopover = styled(Popover)((theme) => ({
    "&.MuiPopover-paper": {
        width: '270px',
        borderRadius: '4px',
        boxShadow: '3px 3px 5px 0 rgba(132, 138, 145, 0.4)',
        border: 'solid 1px #caced1',
    },
}));
StyledTextField = styled(TextField)(({theme}) => ({
    "& .MuiOutlinedInput-root": {
        paddingRight: "0",
        fontSize: '14px',
        borderRadius: '4px',
    },

    "&.MuiOutlinedInput-root.Mui-error": {
        "& > fieldset": {
            borderColor: "#d32f2f",
            borderWidth: "1px",
        },
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        color: "#6f7780",
        borderWidth: "1px",

        "& > fieldset": {
            borderColor: "#80a1fd",
            borderWidth: "1px",
        },
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
        backgroundColor: "#f3f4f5",
        "&:hover": {
            "& > fieldset": {
                borderColor: "rgba(0, 0, 0, 0.26);",
            }
        }
    },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#80a1fd",
        }
    },
    "& .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        "&:hover": {
            color: "#80a1fd",
        },
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#80a1fd",
    },
}));
StyledFilterTextField = styled(TextField)(({theme}) => ({
    "& .MuiOutlinedInput-root": {
        paddingRight: "0",
        fontSize: '12px',
        borderRadius: '4px',
    },
    "& .MuiOutlinedInput-input": {
        padding: "11px 14px"
    },

    "&.MuiOutlinedInput-root.Mui-error": {
        "& > fieldset": {
            borderColor: "#d32f2f",
            borderWidth: "1px",
        },
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        color: "#6f7780",
        borderWidth: "1px",

        "& > fieldset": {
            borderColor: "#80a1fd",
            borderWidth: "1px",
        },
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
        backgroundColor: "#f3f4f5",
        "&:hover": {
            "& > fieldset": {
                borderColor: "rgba(0, 0, 0, 0.26);",
            }
        }
    },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#80a1fd",
        }
    },
    "& .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        "&:hover": {
            color: "#80a1fd",
        },
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#80a1fd",
    },
}));
StyledDatePicker = styled(DatePicker)(({theme}) => ({
    "& .MuiOutlinedInput-root": {
        width: "130px",
        paddingRight: "0",
        fontSize: '12px',
        borderRadius: '4px',
        paddingLeft: "3px",
        display: "flex",
        justifyContent: "space-between",
        order: 2,
        marginLeft: 0,
    },
    "& .MuiOutlinedInput-input": {
        padding: "10px 4px 10px 8px",
        fontSize: "12px",
        maxWidth: "85px",
    },
    "&.MuiOutlinedInput-root.Mui-error": {
        "& > fieldset": {
            borderColor: "#d32f2f",
            borderWidth: "1px",
        },
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        color: "#6f7780",
        borderWidth: "1px",
        "& > fieldset": {
            borderColor: "#80a1fd",
            borderWidth: "1px",
        },
    },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#80a1fd",
        },
        "& > .date-clear-button": {
            opacity: 1,
            width: "16px",
            height: "16px",
            border: "1px solid rgba(0, 0, 0, 0.54)",
            padding: "4px",
        }
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#80a1fd",
    },
    '& .MuiInputAdornment-root': {
        "& button:hover": {
            backgroundColor: "transparent",
        },
        marginRight: "12px"
    },
    ".date-clear-button": {
        "&:hover": {
            backgroundColor: "transparent",
        },
        opacity: 0,
        order: 1,
        fontSize: "16px"
    },

}));

StyledButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        color: "#fff",
        backgroundColor: "#80a1fd",
        borderRadius: "25px",
        textTransform: "capitalize",
        fontWeight: "300",
        padding: "6px 40px",
        "&:hover": {
            backgroundColor: "#80a1fd",
        },
        "@media (max-width: 600px)": {
            marginTop: "20px",
            fontSize: "12px",
        },

    }
}));

StyledCircularProgress = styled(CircularProgress)((theme) => ({
    "&.MuiCircularProgress-root": {
        color: "#80a1fd",
        position: "absolute",
        "&.light-background": {
            position: "relative",
        }
    }
}));
StyledSmallCircularProgress = styled(CircularProgress)((theme) => ({
    "&.MuiCircularProgress-root": {
        color: "#fff",
    }
}));

StyledTableContainer = styled(TableContainer)((theme) => ({
    maxHeight: "100%",
    minHeight: "400px",
    maxWidth: 400,
    overflow: "scroll",
    minWidth: "-webkit-fill-available",
    "&.MuiTableContainer-root": {
        "&::-webkit-scrollbar": {
            height: "6px",
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(187, 194, 201, 0.70)",
                borderRadius: "5px",
            }
        }
    },
    "&.MuiPaper-root": {
        boxShadow: "0 0 0 #fff",
        borderRadius: "0px",
        overflow: "auto",
    }
}));
StyledHistoryTableContainer = styled(TableContainer)((theme) => ({
    "&.MuiTableContainer-root": {
        minHeight: "-webkit-fill-available",
        maxWidth: 400,
        overflow: "scroll",
        minWidth: "-webkit-fill-available",
        "&::-webkit-scrollbar": {
            height: "6px",
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(187, 194, 201, 0.70)",
                borderRadius: "5px",
            }
        }
    },
    "&.MuiPaper-root": {
        boxShadow: "0 0 0 #fff",
        borderRadius: "0px",
        overflow: "auto",
    }
}));

StyledTable = styled(Table)((theme) => ({
    "&.MuiTable-root": {
        height: "max-content",
        whiteSpace: "nowrap",
        width: "-webkit-fill-available",
        border: "none",
    }
}));

StyledTableHead = styled(TableHead)((theme) => ({
    "&.MuiTableHead-root": {
        position: "sticky",
        top: "0",
        color: "#252423",
        fontSize: "14px",
        backgroundColor: "#f7f7f8",
        "@media (max-width: 1200px)": {
            fontSize: "12px",
        },
    },
}));


StyledTableRow = styled(TableRow)((theme) => ({
    "&.MuiTableRow-root": {
        color: "rgba(37, 36, 35, 0.74)",
        height: "30px",
        "&:nth-of-type(odd)": {
            backgroundColor: "#fff",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "rgba(111, 119, 128, 0.14)",
            },
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#f8f8f8",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "rgba(111, 119, 128, 0.14)",
            },
        },
    }
}));

StyledTableCell = styled(TableCell)((theme) => ({
    "&.MuiTableCell-root": {
        borderBottom: "none",
        color: "rgba(37, 36, 35, 0.74)",
        padding: "1px 16px",
        fontSize: "12px",
        "&:last-child": {
            width: "10px"
        },
        "&.data_loading": {
            color: "#C8C8C8"
        },
        "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "1px 8px",
        }
    },
    "&.MuiTableCell-head": {
        color: "#3f4244",
        padding: "4px 16px !important",
        fontSize: "12px",
        fontWeight: "600",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#e5e6e7",
            cursor: "pointer",
        },
        "@media (max-width: 600px)": {
            fontSize: "12px",
        },
    }
}));


StyledTablePagination = styled(TablePagination)((theme) => ({
    "&.MuiTablePagination-root": {
        color: "rgba(37, 36, 35, 0.74)",
        backgroundColor: "transparent",
        borderBottom: 'none',
        overflow: "hidden",
        minHeight: "52px",
        width: "100%",
        "@media  (max-width: 600px)": {
            fontSize: "12px",
            overflow: "hidden",
        },
        "@media (max-width: 600px)": {
            maxWidth: "400px",
            overflowX: "auto",
            width: "100%",
            minWidth: "-webkit-fill-available",
            "&::-webkit-scrollbar": {
                height: "6px",
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(187, 194, 201, 0.70)",
                    borderRadius: "5px",
                }
            }
        },
    },

    "& .MuiTablePagination-select ": {
        padding: "5px",
        paddingRight: "34px !important",
        borderRadius: "5px",
        backgroundColor: "#f4f4f4",
        "&:hover": {
            // backgroundColor: "#000",
        },
        "@media (max-width: 600px)": {
            fontSize: "12px",
        },
    },
    "& .MuiTablePagination-selectIcon": {
        fontSize: "1.3rem",
    },
    "& .MuiTablePagination-selectLabel ": {
        fontSize: "12px",
    },
    "& .MuiTablePagination-displayedRows": {
        display: "flex",
        justifyContent: "space-around",
        fontSize: "14px",
        minWidth: "120px",
        "@media (max-width: 600px)": {
            fontSize: "12px",
        },
    },
    "& .MuiTablePagination-menuItem": {
        padding: "5px !important",
        fontSize: "13px !important",
    },

}));

StyledPaginationIconButton = styled(IconButton)((theme) => ({
    "&.MuiIconButton-root": {
        color: "#80a1fd",
        "&:hover": {
            backgroundColor: "transparent"
        },
        "&:disabled": {
            color: "#bfd0ff",
        }

    }
}));

StyledProfileIconButton = styled(IconButton)((theme) => ({
    "&.MuiIconButton-root": {
        width: '30px',
        height: '30px',
        fontSize: '12px',
        color: '#fff',
        backgroundColor: '#80a1fd',
        marginRight: '20px',
    }
}));
StyledList = styled(List)((theme) => ({
    "&.MuiList-root": {
        paddingTop: "50px",
        position: "fixed",
        top: "50px",
        width: "inherit"
    }
}));
StyledAddList = styled(List)((theme) => ({
    "&.MuiList-root": {
        padding: "0",
        width: "180px",
        border: '1px solid #caced1',
        borderRadius: '4px',
    }
}));


StyledNextButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#80a1fd",
        color: "#fff",
        fontSize: "13px",
        width: "137px",
        padding: "7px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
        border: "none",
        "&:hover": {
            backgroundColor: "#80a1fd",
        },
        "&:disabled": {
            backgroundColor: "#e9ebed",
            color: "#828991"
        }
    },
}));
StyledConfirmButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#80a1fd",
        color: "#fff",
        fontSize: "13px",
        width: "auto",
        padding: "7px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
        border: "none",
        "&:hover": {
            backgroundColor: "#80a1fd",
        }
    },
}));
StyledDownloadButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#80a1fd",
        color: "#fff",
        fontSize: "13px",
        padding: "6px 12px",
        marginBottom: "6px",
        borderRadius: "4px",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#80a1fd",
        }
    },
}));
StyledApplyButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#80a1fd",
        color: "#fff",
        fontSize: "12px",
        width: "80px",
        padding: "4px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#80a1fd",
        }
    },
}));

StyledBackButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#6f7780",
        border: "1px solid #caced1",
        fontSize: "13px",
        width: "130px",
        padding: "6px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
        marginRight: "20px",
        "&:hover": {
            color: "#80a1fd",
            border: "1px solid #80a1fd",
        }
    },
}));
StyledDialogBackButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#6f7780",
        border: "1px solid #caced1",
        fontSize: "15px",
        width: "130px",
        padding: "6px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
        marginRight: "20px",
        fontWeight: "400",
        letterSpacing: "0.3px",
        "&:hover": {
            color: "#80a1fd",
            border: "1px solid #80a1fd",
        }
    },
}));
StyledDialogNextButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#80a1fd",
        border: "1px solid #caced1",
        fontSize: "15px",
        width: "130px",
        padding: "6px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
        marginRight: "20px",
        fontWeight: "400",
        letterSpacing: "0.3px",
        "&:hover": {
            color: "#80a1fd",
            border: "1px solid #80a1fd",
        }
    },
}));
StyledAdvancedSearchButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#80a1fd",
        border: "1px solid #80a1fd",
        fontSize: "13px",
        padding: "6px 24px",
        borderRadius: "4px",
        textTransform: "capitalize",
        fontWeight: "400",
        letterSpacing: "0.3px",
    },
}));
StyledAdvancedSearchClearButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        width: "37px",
        minWidth: "0",
        height: "37px",
        backgroundColor: "#80a1fd",
        padding: "6px 0",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        boxShadow: 'none',
    },
}));
StyledEditButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#6f7780",
        border: "1px solid #caced1",
        fontSize: "13px",
        padding: "6px 32px",
        borderRadius: "4px",
        textTransform: "capitalize",
        "&:hover": {
            color: "#80a1fd",
            border: "1px solid #80a1fd",
        }
    },
}));
StyledClearButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#6f7780",
        border: "1px solid #caced1",
        fontSize: "12px",
        width: "80px",
        padding: "4px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
        marginRight: "14px",
        "&:hover": {
            color: "#80a1fd",
            border: "1px solid #80a1fd",
        }
    },
}));
StyledCancelButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#848a91",
        border: "1px solid #caced1",
        fontSize: "13px",
        width: "140px",
        padding: "6px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
    },
}));
StyledLeaveButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#d32f2f",
        border: "1px solid #caced1",
        fontSize: "13px",
        width: "140px",
        padding: "6px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
        "&.inactive": {
            color: "#bdbdbd",
        }
    }
}));
StyledExportButton = styled(Button)((theme) => ({
    "&.MuiButton-root": {
        backgroundColor: "#fff",
        color: "#80a1fd",
        border: "1px solid #caced1",
        fontSize: "13px",
        width: "140px",
        padding: "6px 12px",
        borderRadius: "4px",
        textTransform: "capitalize",
    },
}));


StyledAutocomplete = styled(Autocomplete)(({theme}) => ({
    '&.MuiPopper-root-MuiAutocomplete-popper': {
        width: "-webkit-fill-available",
    },
    '&.MuiAutocomplete-option[data-focus="true"]': {
        backgroundColor: '#80a1fd',
    },
    "&.MuiAutocomplete-root": {
        width: "100%",
        backgroundColor: "#fff",
        "@media (max-width: 600px)": {
            fontSize: "12px"
        },
    },
    "&.MuiAutocomplete-option": {
        fontSize: "12px",

    },
    "& .MuiOutlinedInput-root": {
        fontSize: "12px",
        borderRadius: '0px',
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
        color: "#3f4244",
        "& > fieldset": {
            borderColor: "#80a1fd",
            borderWidth: "1px",
        }
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
        backgroundColor: "#f3f4f5",
        "&:hover": {
            "& > fieldset": {
                borderColor: "rgba(0, 0, 0, 0.26);",
            }
        }
    },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#80a1fd",
        }
    },
    "& .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        "&:hover": {
            color: "#80a1fd",
        },
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#80a1fd",
    },
}));
StyledFilterAutocomplete = styled(Autocomplete)(({theme}) => ({
    "&.MuiAutocomplete-root": {
        width: "100%",
        backgroundColor: "#fff",
        "@media (max-width: 600px)": {
            fontSize: "12px"
        },
        "& .MuiAutocomplete-clearIndicator": {
            "&:MuiSvgIcon-root": {
                width: "16px",
                height: "16px",
            }
        },

    },
    "&.MuiAutocomplete-option": {
        fontSize: "12px",
    },
    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 6px;",
        pointerEvents: "none",
    },
    "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment ": {
        fontSize: "12px",
        borderRadius: '4px',
        right: "0",
        "&>*:last-child": {
            marginRight: "4px",
        }
    },
    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
        paddingTop: "3px",
        paddingBottom: "3px",
        paddingRight: "90px !important",
        fontSize: "12px",
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
        color: "#3f4244",
        "& > fieldset": {
            borderColor: "#80a1fd",
            borderWidth: "1px",
        }
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
        backgroundColor: "#f3f4f5",
        pointerEvents: "none",
        "&:hover": {
            "& > fieldset": {
                borderColor: "rgba(0, 0, 0, 0.26);",
            }
        },
        "&.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
            pointerEvents: "auto",
        },
    },
    "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "#80a1fd",
        }
    },
    "& .MuiInputLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        "&:hover": {
            color: "#80a1fd",
        },
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#80a1fd",
    },
    "& .MuiAutocomplete": {
        marginRight: "12px"
    },
    "& .MuiAutocomplete-popUpIndicator": {
        "&:hover": {
            width: "24px",
            height: "24px",
        }
    },
    "& .MuiAutocomplete-tag": {
        backgroundColor: "#e1e3e7 !important",
        color: "#252423 !important",
        borderRadius: "15px",
        height: "26px !important",
        fontSize: "12px",
        maxWidth: "calc(100% - 30px)",
        "&.disabledPartnerCustomer": {
            color: "#ee6723 !important",
        }
    },
}));

StyledFormControl = styled(FormControl)((theme) => ({
    "&.MuiFormControl-root": {
        width: "100%",
        marginBottom: "32px",
    },
}));
StyledFormControlLabel = styled(FormControlLabel)((theme) => ({
    "&.MuiFormControlLabel-root": {
        marginRight: "60px",
    },
    "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
        fontSize: "14px !important",
    },
}));


StyledSelect = styled(Select)(({theme}) => ({
    "&.MuiOutlinedInput-input": {
        width: "255px",
        padding: "5.5px 14px",
        cursor: "pointer",
        fontSize: "12px",
        "@media (max-width: 600px)": {
            width: "200px",
        },
    },
    "&.MuiOutlinedInput-root": {
        marginBottom: "12px",
        fontSize: "12px",
        "&:last-child": {
            marginBottom: "0",
        },
        "&:hover > fieldset": {
            color: "#3f4244",
            borderColor: "#80a1fd",
            borderWidth: "1px",
        }
    },

    "&.MuiOutlinedInput-root.Mui-focused": {
        color: "#3f4244",
        borderColor: "#80a1fd",
        borderWidth: "1px",
        "& > fieldset": {
            color: "#3f4244",
            borderColor: "#80a1fd",
            borderWidth: "1px",
        }
    },

}));

StyledInputLabel = styled(InputLabel)(({theme}) => ({
    "&.MuiFormLabel-root:hover": {
        "& > fieldset": {
            borderColor: "#80a1fd",
        }
    },
    "&.MuiFormLabel-root": {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
    },
    "&.MuiFormLabel-root.Mui-focused": {
        color: "#80a1fd",
    },

}));
StyledFormHelperText = styled(FormHelperText)(({theme}) => ({
    "&.MuiFormHelperText-root": {
        fontSize: "11px",
        lineHeight: "0.7",
        position: "absolute",
        bottom: "-16px",
        right: "0",
        color: "#d32f2f"
    }
}));

//Advanced Search Dialog
StyledAdvancedSearchDialog = styled(Dialog)(({theme}) => ({
    "& .MuiBackdrop-root": {
        backgroundColor: "rgb(0 0 0 / 70%)",
        backdropFilter: "blur(1px)",
    },
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "700px",
            height: "620px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    }
}));
StyledAdvancedSearchDialogTitle = styled(DialogTitle)(({theme}) => ({
    "&.MuiDialogTitle-root": {
        width: "100%",
        backgroundColor: "#80a1fd",
        textTransform: "uppercase",
        fontWeight: "400",
        textAlign: "center",
        color: "#fff",
        fontSize: "18px",
    },

}));

StyledAdvancedSearchDialogContent = styled(DialogContent)(({theme}) => ({
    "&.MuiDialogContent-root": {
        padding: "18px 32px",
        width: "100%",
    },
}));

StyledAdvancedSearchDialogContentText = styled(DialogContentText)(({theme}) => ({
    "&.MuiDialogContentText-root": {
        fontSize: "15px",
        color: "#3F4244",
        lineHeight: "2.4",
    },
}));

StyledAdvancedSearchDialogActions = styled(DialogActions)(({theme}) => ({
    "&.MuiDialogActions-root": {
        paddingBottom: "18px",
    },
}));
StyledAdvancedDialogTypography = styled(Typography)(({theme}) => ({
    "&.MuiTypography-root": {
        fontSize: "12px",
        color: "#9a9a9a",
        fontStyle: "italic",
        marginTop: "6px",
    },
}));
StyledAdvancedErrorDialogTypography = styled(Typography)(({theme}) => ({
    "&.MuiTypography-root": {
        fontSize: "12px",
        color: "#d32f2f",
        marginTop: "6px",
    },
}))

StyledNotFoundTypography = styled(Typography)(({theme}) => ({
    "&.MuiTypography-root": {
        fontSize: "16px",
        color: "#3F4244",
        marginTop: "6px",
        fontWeight: "bold",
    },
}));
StyledFoundTypography = styled(Typography)(({theme}) => ({
    "&.MuiTypography-root": {
        fontSize: "16px",
        color: "#3F4244",
        marginBottom: "12px",
        fontWeight: "bold",
    },
}));
StyledNumberTypography = styled(Typography)(({theme}) => ({
    "&.MuiTypography-root": {
        fontSize: "14px",
        color: "#3F4244",
        margin: "8px 0",
        paddingBottom: "8px",
        overflow: "overlay",
        maxHeight: "80px",
        height:"auto",
    },
}));

//Dialog
StyledDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
            height: "210px",
            borderRadius: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    }
}));
StyledConfirmDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
            // height: "370px",
            borderRadius: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    }
}));
StyledDialogTitle = styled(DialogTitle)(({theme}) => ({
    "&.MuiDialogTitle-root": {
        width: "100%",
        backgroundColor: "#e56a6d",
        padding: "8px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        fontSize: "16px",
    },

}));
StyledExportDialogTitle = styled(DialogTitle)(({theme}) => ({
    "&.MuiDialogTitle-root": {
        width: "100%",
        backgroundColor: "#80a1fd",
        padding: "8px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        fontSize: "16px",
    },

}));
StyledDialogContent = styled(DialogContent)(({theme}) => ({
    "&.MuiDialogContent-root": {
        padding: "24px 24px 0",
        textAlign: "center",
        maxWidth: "300px",
    },
}));
StyledConfirmDialogContent = styled(DialogContent)(({theme}) => ({
    "&.MuiDialogContent-root": {
        padding: "24px 24px 0",
        textAlign: "center",
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
}));
StyledDialogContentText = styled(DialogContentText)(({theme}) => ({
    "&.MuiDialogContentText-root": {
        fontSize: "12px",
        color: "#8e959c",
        lineHeight: "1.6",
    },
}));
StyledConfirmDialogContentText = styled(DialogContentText)(({theme}) => ({
    "&.MuiDialogContentText-root": {
        fontSize: "12px",
        color: "#8e959c",
        lineHeight: "1.6",
        textAlign: "start",
        marginBottom: "16px",
    },
}));
StyledDialogActions = styled(DialogActions)(({theme}) => ({
    "&.MuiDialogActions-root": {
        padding: "24px",
        "&>:not(:first-of-type)": {
            marginLeft: "20px",
        }
    },
}));
StyledConfirmDialogActions = styled(DialogActions)(({theme}) => ({
    "&.MuiDialogActions-root": {
        padding: "24px",
        width: "500px",
        "&>:not(:first-of-type)": {
            marginLeft: "20px",
        }
    },
}));
StyledAlert = styled(Alert)(({theme}) => ({
    "&.MuiAlert-root": {
        boxShadow: "3px 3px 5px 0 rgba(132, 138, 145, 0.4)",
        borderRight: "solid 1px #caced1",
        borderLeft: "6px solid #0dbb34",
        backgroundColor: "#fff",
        borderRadius: "0px",
        padding: "10px",
        "&.MuiAlert-icon": {
            color: "#0dbb34",
        }
    },
}));
StyledErrorAlert = styled(Alert)(({theme}) => ({
    "&.MuiAlert-root": {
        boxShadow: "3px 3px 5px 0 rgba(132, 138, 145, 0.4)",
        border: "1px solid rgba(240, 86, 89, 0.4)",
        backgroundColor: "#fdeeee",
        borderRadius: "4px",
        padding: "8px",
        minHeight: "60px",
        zIndex: 1,
    },
    "& .MuiAlert-message": {
        padding: "0",
        display: "flex",
        alignItems: "center",
    },
    "& .MuiAlert-action": {
        marginRight: "0",
        padding: "0"
    },
    "&.warning_alert": {
        boxShadow: "3px 3px 5px 0 rgba(132, 138, 145, 0.4)",
        border: "1px solid #febd39",
        backgroundColor: "#FFF6E4",
        borderRadius: "4px",
        padding: "8px",
        minHeight: "60px"
    },

}));
StyledWarningAlert = styled(Alert)(({theme}) => ({
    "&.MuiAlert-root": {
        boxShadow: "3px 3px 5px 0 rgba(132, 138, 145, 0.4)",
        borderRight: "solid 1px #caced1",
        borderLeft: "6px solid #ff9816",
        backgroundColor: "#fff",
        borderRadius: "0px",
        padding: "10px",
        fontSize: "13px",
        color: "#3f4244"
    },
}));
StyledSyncIconButton = styled(IconButton)((theme) => ({
    "&.MuiIconButton-root": {
        marginLeft: "4px",
        marginRight: "2px",
    }
}));
StyledSyncIcon = styled(Sync)((theme) => ({
    "&.MuiSvgIcon-root": {
        width: 16,
        height: 16
    },
    "&.sync_in_progress": {
        animation: "rotate 2s linear infinite",
    },
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(360deg)",
        },
        "100%": {
            transform: "rotate(0deg)",
        },
    },
}));
StyledSortSvgIcon = styled(SvgIcon)((theme) => ({
    "&.MuiSvgIcon-root": {
        width: 16,
        height: 16
    },
}));



StyledLinearProgress = styled(LinearProgress)((theme) => ({
    "&.MuiLinearProgress-root": {
        height: "2px",
        marginBottom: "4px",
        width: "100%",
        ".MuiLinearProgress-bar1Indeterminate": {
            backgroundColor: "#80a1fd",
        },
        ".MuiLinearProgress-bar2Indeterminate": {
            backgroundColor: "#80a1fd",
        },
        ".MuiLinearProgress-bar": {
            backgroundColor: "#e5e6e7",
        }
    }
}));


