import { countries_and_states } from "./countries_and_states"

export const DIDsTableStructure = [
    {
        "columnHeader": "DID",
        "valueProperty": "number"
    },
    {
        "columnHeader": "Provider",
        "valueProperty": "carrier"
    },
    {
        "columnHeader": "Status",
        "valueProperty": "status"
    },
    {
        "columnHeader": "Rate Center",
        "valueProperty": "rateCenter"
    },
    {
        "columnHeader": "Country",
        "valueProperty": "country"
    },
    {
        "columnHeader": "State",
        "valueProperty": "state"
    }
]

export  const AddNewDIDFields = {
    status: {
        required: true,
        label: "Status",
        options: () => {
            return ["Available", "Assigned", "Aging", "Reserved", "Returned"];
        }
    },
    carrier: {
        required: true,
        label: "Provider",
        options: () => {
            return ["Bandwidth", "BluIP", "BluIP TFN", "Inteliquent", "Lumen ELS", "Lumen LI", "Peerless", "Verizon", "Other"];
        }
    },
    type: {
        required: true,
        label: "Type",
        options: ()=> {
            return [ "Pseudo", "Regular", "Toll-free"];
        }
    },
    portStatus: {
        required: false,
        label: "Port Status",
        options: ()=> {
            return ["Ported-in", "Ported-out"];
        }
    },
    country: {
        required: true,
        label: "Country",
        options: () => {
            return ["United States", "Canada"];
        }
    },
    state: {
        required: true,
        label: "State/Province",
        options: (country)=> {
            const countryKey = country === "Canada" ? country : "USA";
            return countries_and_states[countryKey].states;
        }
    },
    partner: {
        required: false,
        label: "Partner Assigned to"
    },
    customer: {
        required: false,
        label: "Customer Assigned to"
    },
    number: {
        required: true,
        label: "DID"
    },
    rateCenter: {
        required: true,
        label: "Rate Center"
    },
    city: {
        required: false,
        label: "City"
    },
    sms: {
        required: false,
        label: "SMS",
        options: () => {
            return ["Enabled", "Disabled"];
        }
    },
    mms: {
        required: false,
        label: "MMS",
        options: () => {
            return ["Enabled", "Disabled"];
        }
    },
    smsMmsProvider: {
        required: false,
        label: "SMS/MMS Provider",
        options: () => {
            return ["Bandwidth", "BluIP", "BluIP TFN", "Inteliquent", "Lumen ELS", "Lumen LI", "Peerless", "Verizon", "Other"];
        }
    },
    fax: {
        required: false,
        label: "Fax",
        options: () => {
            return ["Enabled", "Disabled"];
        }
    },
    BSEnterpriseName: {
        required: false,
        label: "Enterprise Name"
    },
    BSEnterpriseId: {
        required: false,
        label: "Enterprise ID"
    },
    BSGroupName: {
        required: false,
        label: "Group Name"
    },
    BSGroupId: {
        required: false,
        label: "Group ID"
    },
}

export const didInitialState = {
    number: "",
    status: {id: "", label: ""},
    carrier: {id: "", label: ""},
    type: {id: "", label: ""},
    rateCenter: "",
    portStatus: {id: "", label: ""},
    country: {id: "United States", label: "United States"},
    state: {id: "", label: ""},
    city: "",
    partner: {id: "", label: ""},
    customer: {id: "", label: ""},
    sms: false,
    mms: false,
    smsMmsProvider: {id: "", label: ""},
    fax: false,
    BSEnterpriseName: "",
    BSEnterpriseId: "",
    BSGroupName: "",
    BSGroupId: ""
}

export const didErrorsInitialState = {
    number: [],
    status: [],
    carrier: [],
    type: [],
    rateCenter: [],
    portStatus: [],
    country: [],
    state: [],
    city: [],
    partner: [],
    customer: [],
    sms: [],
    mms: [],
    smsMmsProvider: [],
    fax: [],
    BSEnterpriseName: [],
    BSEnterpriseId: [],
    BSGroupName: [],
    BSGroupId: []
}
export const didExternalDataErrorsInitialState = [
    {invalid: false, value: "", property: "npa_nxx", label: "NPA-NXX"},
    {invalid: false, value: "", property: "rateCenter", label: "Rate Center"},
    {invalid: false, value: "", property: "country", label: "Country"},
    {invalid: false, value: "", property: "state", label: "State"},
    {invalid: false, value: "", property: "city", label: "City"},
]

export const didInitialStateForFilters = {
    status: [],
    carrier: [],
    dateFrom: null,
    dateTo: null,
    country: {id: "", label: ""},
    state: [],
    city: "",
    rateCenter: "",
    partner: [],
    customer: [],
    type: [],
    portStatus: [],
    BSEnterpriseId: "",
    BSGroupId: "",
    services: []
}

export const propertiesNotToBeEditted = [
    "number",
    // "type",
    // "rateCenter",
    // "country",
    // "state",
    // "city"
]

export const HistoryTableStructure = {
    number: "DID",
    status: "Status",
    carrier: "Provider",
    rateCenter: "Rate Center",
    type: "Type",
    portStatus: "Port Status",
    country: "Country",
    state: "State",
    city: "City",
    partnerID: "Partner Assigned to",
    customerID: "Customer Assigned to",
    sms: "SMS",
    mms: "MMS",
    smsMmsProvider: "SMS/MMS Provider",
    fax: "Fax",
    BSEnterpriseId: "Broadsoft Enterprise ID",
    BSEnterpriseName: "Broadsoft Enterprise Name",
    BSGroupId: "Broadsoft Group ID",
    BSGroupName: "Broadsoft Group Name"
}

export const BroadsoftDataFields = {
    BSEnterpriseId: {
        label: "Broadsoft Enterprise ID"
    },
    BSGroupId: {
        label: "Broadsoft Group ID"
    },
    BSEnterpriseName: {
        label: "Broadsoft Enterprise Name"
    },
    BSGroupName: {
        label: "Broadsoft Group Name"
    },
}


const bulkInsertErrorsStructure1 = {
    message: "invalid_data",
    errors: [
       {
           type: "missing_columns",
           columns: ["status", "number"]
       },
       {
        type: "missing_required_data",
        location: {
            0: ["status"],
            3: ["number", "state"]
        }
       },
       {
        type: "did_duplication",
        location: {
           5056667777: [4, 6, 10],
           5065345355: [2, 8]
        }
       },
       {
        type: "existing_did",
        location: {
           1: 6663333324,
           8: 4645646466,
        }
       },
       {
        type: "invalid_data",
        location: [
            {
                row: 4,
                column: "partnerID",
                value: "Test Partner 5"
            }
        ]
       }
    ]

}


export const bulkImportErrorMessages = {
    "no_data_found": {
        message: "File contains no data."
    },
    "missing_required_columns": {
        message: "The following columns are missing from the file:"
    },
    "missing_required_data": {
        message: "Required data is missing on the following rows:"
    },
    "did_duplication": {
        message: "DID duplication on the following rows:"
    },
    "existing_did": {
        message: "The following DIDs already exist:"
    },
    "non_existent_did": {
        message: "The following DIDs do not exist in database:"
    },
    "invalid_data": {
        message: "Invalid data on the following rows:"
    },
    "invalid_data_warning": {
        message: "The following DIDs do not have valid NPA/NXX combination:"
    },
    "invalid_file_size": {
        message: "File size exceeds the maximum limit of 10MB."
    },
    "data_mismatch": {
        message: "Values do not match on the following rows:"
    },
    "mismatch_data_warning": {
        message: "Values do not match on the following rows:"
    },
    "blocked_did": {
        message: "The following DIDs are currently held for a customer and cannot be edited."
    },
    "other_error": {
        message: "Something went wrong. Please try again."
    }
}

export const Actions = {
    "new_did_success": {
        message: "DID has been created successfully."
    },
    "edit_did_success": {
        message: "DID has been updated successfully."
    },
    "return_did_success": {
        message: "DID has been returned successfully."
    },
    "bulk_import_success": {
        message: "DIDs have been imported successfully."
    },
    "bulk_update_success": {
        message: "DIDs have been updated successfully."
    }
}

export const faxBSData = {
    BSEnterpriseName: "Fax Back",
    BSEnterpriseId: "00017",
    BSGroupName: "Fax Back",
    BSGroupId: "0001700001"
}