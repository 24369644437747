import React from 'react';
import {Box, Typography, SvgIcon} from '@mui/material';
import FileUploadDrop from '../FileUploadDrop';
import ErrorAlert from '../ErrorAlert';

function SyncDataStepTwo({carrier, file, setFile, loading, errors, errorsData, resetErrors, hasAPI}) {


    return (
        <Box className="step_two_container">
            <Box className="box">
                {hasAPI(carrier.id) ?

                    <Typography className="step_one_paragraph">
                        The comparison of data between the DID Management System and {carrier.label} is carried out
                        automatically. Click on the Next button to start the data comparison process and
                        detect any inconsistencies between the two systems.
                    </Typography> :
                    <>
                        <Typography className="step_one_paragraph-upload">
                            Upload the excel file that contains all DIDs that BluIP
                            owns in {carrier.label}. The file should be in .xls or or .xlsx format.
                        </Typography>
                        <FileUploadDrop file={file} setFile={setFile} resetErrors={resetErrors}
                                        disableRemove={loading}/>
                    </>
                }
            </Box>
            <Box className="box">
                {errors &&
                    <Box className="file_error_block">
                        <ErrorAlert
                            key={errorsData.type}
                            type={errorsData.type}
                            location={errorsData.location}
                        />
                    </Box>
                }
                {loading &&
                    <Box className="download_block_exception">
                        <SvgIcon className="progress_icon"/>
                        <Typography className="file_download_text_progress">
                            {`Data validation is in progress.`}
                        </Typography>
                    </Box>
                }
            </Box>

        </Box>
    )
}

export default SyncDataStepTwo
