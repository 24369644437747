const { REACT_APP_LOGIN_REDIRECT_URI, REACT_APP_CLIENT_ID, REACT_APP_SCOPES} = process.env;

export const msalConfig = {
    auth: {
        clientId: REACT_APP_CLIENT_ID,
        authority: "https://login.microsoftonline.com/9e829146-9e85-4c5f-bcdd-27c3d0ab91db/", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: REACT_APP_LOGIN_REDIRECT_URI,
        postLogoutRedirectUri:  REACT_APP_LOGIN_REDIRECT_URI
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        allowRedirectInIframe: true
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [REACT_APP_SCOPES],
};
