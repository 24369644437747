import React, {useState} from 'react'
import {Box,SvgIcon, Popover} from '@mui/material';
import {StyledMenuItem, StyledAddList} from "../../styles/mui_styles";
import {MoreVert} from '@mui/icons-material';
const { REACT_APP_ONE_DRIVE_URL } = process.env;

function ExceptionsPopover() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleView = (to) => {
        window.open(REACT_APP_ONE_DRIVE_URL);
        handleClose(); 
    }

  return (
    <Box>
        <SvgIcon onClick={handleClick} className="icon_more"/>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <StyledAddList>
                <StyledMenuItem onClick={() => handleView('history')}>
                    View exceptions history
                </StyledMenuItem>
            </StyledAddList>
        </Popover>
    </Box>
  )
}

export default ExceptionsPopover;
